import { TextInput } from "suomifi-ui-components"
import { NormalizedRule } from "../../types"
import { useIntl } from "react-intl"
import { Control, Controller } from "react-hook-form"

export const AGE_SETTING_KEY = "age"
export const AGE_MIN_SETTING_KEY = "age_min"

type Props = {
  control: Control
  configKey: string
  rule: NormalizedRule
  onChange: (rule: NormalizedRule, key: string, value: string) => void
}

const MIN = 1
const MAX = 120

const validate = (value: string) => {
  const numValue = +value
  return numValue >= MIN && numValue <= MAX
}

const AgeRuleConfig = ({ control, configKey, rule, onChange }: Props) => {
  const { formatMessage } = useIntl()
  return (
    <Controller
      name={rule.identification}
      defaultValue={rule.ruleConfig[configKey]}
      control={control}
      rules={{ validate }}
      shouldUnregister
      render={({ field, fieldState }) => (
        <TextInput
          {...field}
          data-testid={`age-rule-config-${rule.ruleId}`}
          labelText={formatMessage({ id: configKey })}
          type="number"
          min={MIN}
          max={MAX}
          onChange={value => {
            // TextInputValue seems to always be string despite the typings
            const stringValue = value as string
            onChange(rule, configKey, stringValue)
            field.onChange(value)
          }}
          status={fieldState.error ? "error" : "default"}
          statusText={
            fieldState.error ? formatMessage({ id: "admin.validation.range" }, { min: MIN, max: MAX }) : undefined
          }
        />
      )}
    />
  )
}

export default AgeRuleConfig
