import { useMutation, useQueryClient } from "@tanstack/react-query"
import { deleteService } from "../api"

const useDeleteService = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteService,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["services"] })
    }
  })
}

export default useDeleteService
