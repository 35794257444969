import { landing, unauthorized } from "../utils/routerPaths"
import { redirect } from "react-router-dom"
import { StatusError } from "../StatusError"

export const homePageLoader = async () => {
  const response = await fetch("/admin/users/user/authorizations")
  if (response.status === 401) {
    return redirect(landing)
  } else if (response.status === 403) {
    return redirect(unauthorized)
  } else if (!response.ok) {
    throw new StatusError(response.statusText, response.status)
  }
  const authorizations = await response.json()
  if (authorizations) {
    if (authorizations.includes("SERVICEMGMT") || authorizations.includes("RULEMGMT")) {
      return redirect("/services")
    } else if (authorizations.includes("USERMGMT")) {
      return redirect("/users")
    } else if (authorizations.includes("OFFICERADMIN")) {
      return redirect("/officers")
    } else if (authorizations.includes("NOTIFICATIONMGMT")) {
      return redirect("/notifications")
    }
  }
}

const responseLoader: (url: string) => () => Promise<Response> = url => {
  return async () => {
    const response = await fetch(url)
    if (response.status === 401) {
      return redirect(landing)
    } else if (response.status === 403) {
      return redirect(unauthorized)
    } else if (!response.ok) {
      throw new StatusError(response.statusText, response.status)
    } else {
      return response
    }
  }
}

export const authorizationsLoader = responseLoader("/admin/users/user/authorizations")
export const officersLoader = responseLoader("/admin/officers/officers")
