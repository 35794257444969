import { Button, InlineAlert, ModalContent, ModalFooter, ModalTitle, TextInput } from "suomifi-ui-components"
import { FormattedMessage, useIntl } from "react-intl"
import { Controller, useForm } from "react-hook-form"
import GenericInlineAlert from "../components/GenericInlineAlert"
import { ErrorCode, OrganizationForm } from "../types"
import { useEffect, useRef, useState } from "react"
import { ApiError } from "../utils/error"

type Props = {
  error?: unknown | null
  organization?: OrganizationForm
  title: string
  onSave: (organization: OrganizationForm) => void
  onClose: () => void
}

const languages = ["fi", "sv", "en"]

const OrganizationModalForm = ({ error, organization, title, onSave, onClose }: Props) => {
  const { formatMessage } = useIntl()
  const { control, handleSubmit, setError } = useForm({
    defaultValues: organization ? organization : { names: { fi: "", sv: "", en: "" } }
  })
  const ref = useRef<HTMLFormElement>(null)
  const [duplicateError, setDuplicateError] = useState(false)

  useEffect(() => {
    if (error && error instanceof ApiError) {
      const errorOptions = { message: "admin.common.errors.invalid" }
      if (error.error.errorCode === ErrorCode.DUPLICATE_ORG_NAME_FI) {
        setError("names.fi", errorOptions)
        setDuplicateError(true)
      } else if (error.error.errorCode === ErrorCode.DUPLICATE_ORG_NAME_SV) {
        setError("names.sv", errorOptions)
        setDuplicateError(true)
      } else if (error.error.errorCode === ErrorCode.DUPLICATE_ORG_NAME_EN) {
        setError("names.en", errorOptions)
        setDuplicateError(true)
      }
    } else {
      setDuplicateError(false)
    }
  }, [error, setError])

  return (
    <>
      <ModalContent>
        <form onSubmit={handleSubmit(onSave)} ref={ref}>
          <ModalTitle>{title}</ModalTitle>
          <p>
            <FormattedMessage id="admin.organizations.modal.text" />
          </p>
          {languages.map(lang => (
            <Controller
              key={lang}
              control={control}
              name={`names.${lang}`}
              rules={{
                required: "admin.validation.required",
                pattern: { value: /\S+/, message: "admin.validation.required" }
              }}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  fullWidth
                  labelText={formatMessage({ id: `admin.organizations.modal.name_${lang}` })}
                  className="mb-m"
                  {...field}
                  status={error ? "error" : "default"}
                  statusText={error && formatMessage({ id: error.message })}
                  maxLength={200}
                  data-testid={`org-form-name-${lang}-input`}
                />
              )}
            />
          ))}
        </form>
      </ModalContent>
      <ModalFooter>
        {error !== null &&
          (duplicateError ? (
            <InlineAlert status="error">
              <FormattedMessage id="admin.organizations.error.duplicateOrganization" />
            </InlineAlert>
          ) : (
            <GenericInlineAlert />
          ))}
        <Button onClick={() => ref.current?.requestSubmit()} data-testid="org-form-save-button">
          <FormattedMessage id="admin.button.tallenna" />
        </Button>
        <Button variant="secondary" onClick={onClose} data-testid="org-form-cancel-button">
          <FormattedMessage id="admin.button.peruuta" />
        </Button>
      </ModalFooter>
    </>
  )
}

export default OrganizationModalForm
