import { format } from "date-fns"
import { fi } from "date-fns/locale/fi"
import { sv } from "date-fns/locale/sv"
const DATE_FORMAT = "P"

const formatDate = (date: number, lang: string): string => {
  return format(new Date(date), DATE_FORMAT, { locale: lang === "fi" ? fi : sv })
}

export { formatDate }
