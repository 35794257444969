import { useSessionStorage } from "react-use"
import { Language } from "../utils/languages"
import { useEffect } from "react"
import { setDefaultLocale } from "react-datepicker"
import { Header } from "../Header/Header"
import { Outlet } from "react-router-dom"
import { Footer } from "../Footer/Footer"
import styles from "./Root.module.scss"
import { Helmet, HelmetProvider } from "react-helmet-async"
import I18n from "../I18n"
import type { RootContext } from "../hooks/useRootContext"

const searchParams = new URLSearchParams(document.location.search)
const showTranslationKeys = searchParams.get("notranslation") === "true"

const Root = () => {
  const [locale, setLocale] = useSessionStorage<Language>("lang", "fi")
  const outletContext: RootContext = { locale }

  useEffect(() => {
    setDefaultLocale(locale)
  }, [locale])

  return (
    <HelmetProvider>
      <Helmet htmlAttributes={{ lang: locale }} />
      <I18n locale={locale} showTranslationKeys={showTranslationKeys}>
        <Header lang={locale} onLangChange={setLocale} />
        <div className={styles.content}>
          <Outlet context={outletContext} />
        </div>
        <Footer />
      </I18n>
    </HelmetProvider>
  )
}

export default Root
