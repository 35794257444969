import { Heading, TextInput, IconSearch } from "suomifi-ui-components"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import OrganizationAddButton from "./OrganizationAddButton"
import OrganizationEditButton from "./OrganizationEditButton"
import OrganizationDeleteButton from "./OrganizationDeleteButton"
import { TimedToast } from "../components/TimedToast"
import useDeleteOrganization from "../hooks/useDeleteOrganization"
import useFilteredOrganizations from "../hooks/useFilteredOrganizations"

const OrganizationsPage = () => {
  const [searchText, setSearchText] = useState("")
  const { organizations } = useFilteredOrganizations(searchText)
  const { formatMessage } = useIntl()
  const deleteMutation = useDeleteOrganization()

  const handleSearchTextChange = (value?: string | number) => {
    setSearchText(value as string)
  }

  return (
    <>
      <TimedToast status={deleteMutation.status}>
        <FormattedMessage id="admin.common.deleted" />
      </TimedToast>
      <Heading variant="h1">
        <FormattedMessage id="admin.organizations.heading" />
      </Heading>
      <div className="mt-l mb-m row align-items-end">
        <div className="col-5">
          <TextInput
            data-testid="org-search-input"
            icon={<IconSearch />}
            labelText={formatMessage({ id: "admin.organizations.search.label" })}
            visualPlaceholder={formatMessage({ id: "admin.info.kirjoita" })}
            fullWidth
            onChange={handleSearchTextChange}
          />
        </div>
        <div className="col text-end">
          <OrganizationAddButton />
        </div>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="admin.organizations.organization" />
            </th>
            <th>
              <FormattedMessage id="admin.organizations.use_count" />
            </th>
            <th>
              <FormattedMessage id="admin.button.muokkaa" />
            </th>
            <th>
              <FormattedMessage id="admin.button.poista" />
            </th>
          </tr>
        </thead>
        <tbody>
          {organizations.map(organization => (
            <tr key={organization.id}>
              <td>{organization.names.fi}</td>
              <td>{organization.services.length}</td>
              <td>
                <OrganizationEditButton organization={organization} />
              </td>
              <td>
                <OrganizationDeleteButton organization={organization} mutation={deleteMutation} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default OrganizationsPage
