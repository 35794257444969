import { useNavigate, useParams } from "react-router-dom"
import { useRuleSet, useServiceByUuid } from "../hooks"
import { Button, Heading } from "suomifi-ui-components"
import { FormattedMessage } from "react-intl"
import { useMemo } from "react"
import { groupRules } from "../utils/ruleSets"

const SelectedRulesPage = () => {
  const { uuid } = useParams()
  const { data: service } = useServiceByUuid(uuid as string)
  const { data: ruleSetMap } = useRuleSet(uuid as string)
  const navigate = useNavigate()

  const groupedRuleSets = useMemo(() => {
    if (!ruleSetMap || !service) return []
    return groupRules(ruleSetMap, service)
  }, [ruleSetMap, service])

  if (!ruleSetMap || !service) {
    return null
  }

  return (
    <>
      <Heading variant="h1" className="mb-m">
        <FormattedMessage id="admin.serviceloc.heading" />
      </Heading>
      <Heading variant="h1" className="mb-m">
        {service.name}
      </Heading>
      <p>{service.description}</p>
      {Object.entries(groupedRuleSets).map(([type, ruleTypes]) => (
        <div key={type}>
          <Heading variant="h2" className="mb-m">
            <FormattedMessage id={type} />
          </Heading>
          <div>
            {Object.entries(ruleTypes).map(([ruleType, ruleGroups]) => (
              <div key={ruleType}>
                <Heading variant="h3">
                  <FormattedMessage id={ruleType} />
                </Heading>
                {Object.entries(ruleGroups).map(([group, selectedRules]) => (
                  <dl key={group}>
                    <dt>
                      <Heading variant="h4">
                        <FormattedMessage id={group} />
                      </Heading>
                    </dt>
                    {selectedRules.map(rule => (
                      <dd key={rule.ruleId}>
                        <b>
                          <FormattedMessage id={rule.ruleId} />
                        </b>
                        <br />
                        <i>
                          <FormattedMessage id={rule.description} />
                        </i>
                      </dd>
                    ))}
                  </dl>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
      <div>
        <Button
          data-testid="selected-rules-back-button"
          variant="secondaryLight"
          onClick={() => navigate(`/services/service/edit/${uuid}`)}
        >
          <FormattedMessage id="admin.button.takaisin" />
        </Button>
      </div>
    </>
  )
}

export { SelectedRulesPage }
