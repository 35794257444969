import useOrganizations from "./useOrganizations"
import { useMemo } from "react"

const useFilteredOrganizations = (searchText: string) => {
  const { data } = useOrganizations()
  const lowercaseSearchText = useMemo(() => (searchText ? searchText.toLowerCase() : searchText), [searchText])
  const filteredOrganizations = useMemo(() => {
    if (data) {
      if (lowercaseSearchText) {
        return data.filter(organization => organization.names.fi.toLowerCase().includes(lowercaseSearchText))
      }
      return data
    }
    return []
  }, [data, lowercaseSearchText])
  return { organizations: filteredOrganizations }
}

export default useFilteredOrganizations
