import { Button, IconLogin } from "suomifi-ui-components"
import { FormattedMessage } from "react-intl"

const LoginButton = ({ lang }: { lang: string }) => {
  return (
    <form method="get" action="/saml/login">
      <Button data-testid="login-button" type="submit" icon={<IconLogin />}>
        <FormattedMessage id="admin.landingpage.login" />
      </Button>
      <input type="hidden" name="lang" value={lang} />
    </form>
  )
}

export { LoginButton }
