import { Checkbox } from "suomifi-ui-components"
import { FormattedMessage } from "react-intl"
import { NormalizedRule } from "../../types"

export const PHASE_SETTING_KEY = "phase"
export const STATUS_SETTING_KEY = "status"
export const OPTIONS = ["KON", "SAN", "SEL"]

type Props = {
  rule: NormalizedRule
  configKey: string
  onClick: (rule: NormalizedRule, key: string, value: string) => void
}

const StatusRuleConfig = ({ rule, onClick, configKey }: Props) => {
  const selectedValues = rule.ruleConfig[configKey]
  const handleClick = (selected: boolean, value: string) => {
    if (selected) {
      onClick(rule, configKey, selectedValues + "," + value)
    } else {
      const newStatuses = selectedValues
        .split(",")
        .filter(current => current !== value)
        .join(",")
      onClick(rule, configKey, newStatuses)
    }
  }

  return (
    <>
      {OPTIONS.map(option => (
        <Checkbox
          key={option}
          data-testid={`status-rule-config-${option}-${rule.ruleId}`}
          checked={selectedValues.includes(option)}
          onClick={({ checkboxState }) => handleClick(checkboxState, option)}
        >
          <FormattedMessage id={option} />
        </Checkbox>
      ))}
    </>
  )
}

export default StatusRuleConfig
