import { FormattedMessage } from "react-intl"
import { Button } from "suomifi-ui-components"
import { useState } from "react"
import OrganizationAddModal from "./OrganizationAddModal"
import { TimedToast } from "../components/TimedToast"

const OrganizationAddButton = () => {
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState("")

  const handleClick = () => {
    setStatus("")
    setVisible(true)
  }

  const handleSuccess = () => {
    setStatus("success")
    setVisible(false)
  }

  return (
    <>
      <TimedToast status={status}>
        <FormattedMessage id="admin.info.tallennettu" />
      </TimedToast>
      <Button onClick={handleClick} data-testid="org-add-button">
        <FormattedMessage id="admin.organizations.add_organization" />
      </Button>
      <OrganizationAddModal visible={visible} onSuccess={handleSuccess} onClose={() => setVisible(false)} />
    </>
  )
}

export default OrganizationAddButton
