import { IntlProvider, useIntl } from "react-intl"
import { ReactNode } from "react"
import { useLocalization } from "./hooks"
import { Helmet } from "react-helmet-async"

const IntlHeader = () => {
  const { formatMessage } = useIntl()
  return <Helmet title={formatMessage({ id: "admin.html.title" })} />
}

interface I18nProps {
  locale: string
  showTranslationKeys: boolean
  children: ReactNode
}

const I18n = ({ locale, showTranslationKeys, children }: I18nProps) => {
  const { data } = useLocalization(locale, showTranslationKeys)

  if (!data) {
    return null
  }

  return (
    <IntlProvider locale={locale} messages={data}>
      <IntlHeader />
      {children}
    </IntlProvider>
  )
}

export default I18n
