import { useMutation, useQueryClient } from "@tanstack/react-query"
import { deleteUser } from "../api"

const useDeleteUser = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (uuid: string) => deleteUser(uuid).then(() => uuid),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["users"] })
  })
}

export default useDeleteUser
