import { FormattedMessage } from "react-intl"
import { Heading } from "suomifi-ui-components"
import { UserPage } from "./UserPage"
import { UserNameForm } from "./UserNameForm"
import { useCreateUser } from "../hooks"
import { TimedToast } from "../components/TimedToast"
import { ErrorNotification } from "../components/ErrorNotification"

const UsersCreatePage = () => {
  const {
    data: createdUser,
    status: createUserStatus,
    isError: isCreateUserError,
    mutate: createUser
  } = useCreateUser()

  return (
    <>
      {isCreateUserError && (
        <ErrorNotification id="create-user-error-notification">
          <FormattedMessage id="admin.error.user.add" />
        </ErrorNotification>
      )}
      <TimedToast status={createUserStatus}>
        <FormattedMessage id="admin.info.tallennettu" />
      </TimedToast>
      <Heading variant="h1" className="mb-m">
        <FormattedMessage id="admin.button.lisaa.kayttaja" />
      </Heading>
      {createdUser ? <UserPage initialUser={createdUser} /> : <UserNameForm onSubmit={createUser} />}
    </>
  )
}

export { UsersCreatePage }
