import styles from "./AppRoutes.module.scss"
import { NavBar } from "../NavBar/NavBar"
import { Outlet } from "react-router-dom"
import { AdminUiNotifications } from "../AdminUiNotifications/AdminUiNotifications"
import useAuthorizations from "../hooks/useAuthorizations"

const AppRoutes = () => {
  const authorizations = useAuthorizations()

  return (
    <>
      {authorizations && <NavBar authorizations={authorizations} />}
      <AdminUiNotifications />
      <div className={`container my-l py-xs ${styles.content}`}>
        <Outlet />
      </div>
    </>
  )
}

export { AppRoutes }
