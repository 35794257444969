import { Checkbox } from "suomifi-ui-components"
import { FormattedMessage } from "react-intl"
import { NormalizedRule } from "../../types"

export const GUARDIAN_ROLE_SETTING_KEY = "guardian_role_enabled"

type Props = {
  rule: NormalizedRule
  onClick: (rule: NormalizedRule, key: string, value: string) => void
}

const GuardianRoleRuleConfig = ({ rule, onClick }: Props) => {
  const { ruleConfig } = rule
  return (
    <Checkbox
      data-testid="guardian-role-rule-config"
      checked={ruleConfig[GUARDIAN_ROLE_SETTING_KEY] === "true"}
      onClick={value => onClick(rule, GUARDIAN_ROLE_SETTING_KEY, value.checkboxState.toString())}
    >
      <FormattedMessage id={GUARDIAN_ROLE_SETTING_KEY} />
    </Checkbox>
  )
}

export default GuardianRoleRuleConfig
