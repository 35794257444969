import { Modal } from "suomifi-ui-components"
import { Organization } from "../types"
import OrganizationEditModalForm from "./OrganizationEditModalForm"

type Props = {
  visible: boolean
  organization: Organization
  onSuccess: (organization: Organization) => void
  onClose: () => void
}

const OrganizationEditModal = ({ visible, organization, onSuccess, onClose }: Props) => {
  return (
    <Modal visible={visible} appElementId="root">
      <OrganizationEditModalForm organization={organization} onSuccess={onSuccess} onClose={onClose} />
    </Modal>
  )
}

export default OrganizationEditModal
