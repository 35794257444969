export type ServiceCreate = {
  name: string
  description: string
  xinstance: string
  memberClass: string
  memberCode: string
  subsystemCode: string
  generateApiServiceIdentifier: boolean
  generateApiSecret: boolean
  generateApiOauthSecret: boolean
  generateRestApiSecret: boolean
}

export type Service = {
  id: number
  apiServiceIdentifier: string | null
  description: string
  active: boolean
  katsoEntityId: unknown
  memberClass: string | null
  memberCode: string | null
  name: string
  ruleSets: unknown[]
  serviceConfig: {
    enabledApis: string[]
    hpaIssueUris: string[]
    ypaIssueUris: string[]
    yyaIssueUris: string[]
    yhaIssueUris: string[]
    hukoIssueUris: string[]
    includeReasons: boolean
    includeOrganizationIdYpa: boolean
    includeOrganizationIdHpa: boolean
    includeForeignCompanies: boolean
    includeForeignPersons: boolean
    includeInfoPrincipals: boolean
    allowMultiSelection: boolean
    allowEmptyVTJRules: boolean
    useNameSeparator: boolean
    nameSeparator: string
  }
  serviceIdentifier: string
  subsystemCode: string | null
  uuid: string
  xinstance: string | null
  generateApiServiceIdentifier: boolean
  generateApiSecret: boolean
  generateApiOauthSecret: boolean
  generateRestApiSecret: boolean
  apiSecret: string | null
  restApiSecret: string | null
  apiOauthSecret: string | null
  apiOauthRedirectUrls: (string & { url?: string })[]
  modified: number
  localizedContent: {
    [lang: string]: LocalizedContent
  }
  serviceType: ServiceType | null
  organization?: Organization
}

export type LocalizedContent = {
  langCode: string
  displayName: string
  url: string | null
}

export enum ServiceType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE"
}

export type RuleSetsByService = {
  [key: string]: RuleSet[]
}

export type RuleSetMap = {
  [key: string]: RuleSet
}

export type RuleConfig = {
  [key: string]: string
}

export type RuleSet = {
  id: number
  type: string
  ruleConfig: { [key: string]: RuleConfig }
  rules: RuleMap
  fixedRules: RuleMap
}

export type RuleMap = {
  [key: string]: Rule
}

export type Rule = {
  defaultConfiguration: RuleConfig
  description: string
  fixed: boolean
  group: string
  identification: string
  localizationKeys: string[]
  ruleId: string
  ruleType: string
  selected: boolean
}

export type NormalizedRule = Rule & { type: string } & { ruleConfig: { [key: string]: string } }

export type OrganizationForm = {
  id?: number
  names: {
    [lang: string]: string
  }
}

export type Organization = {
  id: number
  services: string[]
  names: {
    [lang: string]: string
  }
}

export type AuthorizationRequest = {
  roleName: string
  serviceUuid: string
  user: null
}

export type AuthorizationResponse = {
  id: number
  roleName: string
  serviceUuid: string
  user: null
}

export type IdentityResponse = {
  id: number
  user: null
  userIdentifier: string
  userIdentifierType: string
}

export type IdentityRequest = {
  id: number
  user: User
  userIdentifier: string
  userIdentifierType: string
}

export type Permission = {
  id: number
  roleName: string
  serviceName: string
  serviceUuid: string
  user: object
}

export type User = {
  name: string
  userAuthorizations: AuthorizationResponse[]
  userIdentities: IdentityResponse[]
  uuid: string
}

export type Notification = {
  id?: number
  text: string
  startDate: number
  endDate: number
  channels: string[]
}

export type AdminUiNotification = {
  id: number
  startDate: string
  text: string
}

export type Officer = {
  uuid?: string
  name: string
  userId: string
  organizationId: string
  authorizations: OfficerAuthorization[]
}

export type OfficerAuthorization = {
  issue?: string
  roleName: string
}

export type Label = {
  [index: string]: string
}

export type Issue = {
  uri: string
  labels: Label
  active: boolean
  hukoRoles: string[]
}

export type IssuesMap = {
  [key: string]: Issue[]
}

export type FetchError = {
  status: number
}

export enum Auth {
  SERVICEMGMT = "SERVICEMGMT",
  USERMGMT = "USERMGMT",
  NOTIFICATIONMGMT = "NOTIFICATIONMGMT",
  OFFICERADMIN = "OFFICERADMIN"
}

export enum RuleSetType {
  AUTHORIZATION = "AUTHORIZATION",
  ORG_ROLE = "ORG_ROLE",
  ORG_MANDATES = "ORG_MANDATES",
  ORG_PERSON_MANDATES = "ORG_PERSON_MANDATES"
}

export type OperationStatus = {
  deleteStatus: string
}

export type ErrorResponse = {
  errorCode: ErrorCode
  errorMessage: string
  reqID: string
  userAgent: null
}

export enum ErrorCode {
  DUPLICATE_USER_IDENTITY = 109,
  DUPLICATE_ORG_NAME_FI = 115,
  DUPLICATE_ORG_NAME_SV = 116,
  DUPLICATE_ORG_NAME_EN = 117
}

export type PublicInfo = {
  id: number
  lang: string
  organization: string | undefined
  service: string | undefined
  url: string | null
}
