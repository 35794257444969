import { ReactNode } from "react"
import { Toast } from "suomifi-ui-components"
import styles from "./TimedToast.module.scss"

const SuccessToast = ({ visible, children }: { visible: boolean; children: ReactNode }) => {
  if (!visible) {
    return null
  }

  return <Toast className={styles.timedToast}>{children}</Toast>
}

export { SuccessToast }
