import { RadioButton } from "suomifi-ui-components"
import { FormattedMessage } from "react-intl"
import { NormalizedRule } from "../../types"
import { ChangeEvent } from "react"

export const RIGHTLEVEL_SETTING_KEY = "right.level"
export const LEVELS = ["OI", "P"]

type Props = {
  rule: NormalizedRule
  onClick: (rule: NormalizedRule, key: string, value: string) => void
}

const RightLevelRuleConfig = ({ rule, onClick }: Props) => {
  const { ruleConfig } = rule

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onClick(rule, RIGHTLEVEL_SETTING_KEY, event.target.value)
  }

  const selectedLevel = ruleConfig[RIGHTLEVEL_SETTING_KEY]

  return (
    <>
      {LEVELS.map(level => (
        <RadioButton
          data-testid={`right-level-rule-config-${level}`}
          key={level}
          value={level}
          checked={selectedLevel === level}
          onChange={handleChange}
        >
          <FormattedMessage id={level} />
        </RadioButton>
      ))}
    </>
  )
}

export default RightLevelRuleConfig
