import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Root from "../components/Root"
import { landing, unauthorized } from "../utils/routerPaths"
import { LandingPage } from "../LandingPage/LandingPage"
import { UnauthorizedPage } from "../UnauthorizedPage/UnauthorizedPage"
import { AppRoutes } from "../AppRoutes/AppRoutes"
import { EditServicePage } from "../ServicesPage/EditServicePage"
import { SelectedRulesPage } from "../ServicesPage/SelectedRulesPage"
import { ServiceSettings } from "../ServicesPage/ServiceSettings"
import { ServicesPage } from "../ServicesPage/ServicesPage"
import { UserEditPage } from "../UsersPage/UserEditPage"
import { UsersCreatePage } from "../UsersPage/UsersCreatePage"
import { UsersList } from "../UsersPage/UsersList"
import { OfficerCreatePage } from "../Officers/OfficerCreatePage"
import { OfficerUpdatePage } from "../Officers/OfficerUpdatePage"
import { OfficersList } from "../Officers/OfficersList"
import OrganizationsPage from "../Organizations/OrganizationsPage"
import { NotificationsList } from "../Notifications/NotificationsList"
import { NotificationCreatePage } from "../Notifications/NotificationCreatePage"
import { NotificationUpdatePage } from "../Notifications/NotificationUpdatePage"
import { authorizations } from "./routeIds"
import { deleteOfficerAction, putOfficerAction } from "./actions"
import { authorizationsLoader, homePageLoader, officersLoader } from "./loaders"
import GenericInlineAlert from "../components/GenericInlineAlert"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <></>,
    children: [
      { path: landing, element: <LandingPage /> },
      { path: unauthorized, element: <UnauthorizedPage /> },
      { path: "", loader: homePageLoader, element: <></> },
      {
        id: authorizations,
        path: "",
        element: <AppRoutes />,
        errorElement: <GenericInlineAlert />,
        loader: authorizationsLoader,
        shouldRevalidate: () => false,
        children: [
          { path: "services", element: <ServicesPage /> },
          { path: "services/service/edit/:uuid", element: <EditServicePage /> },
          { path: "services/service/selected-rules/:uuid", element: <SelectedRulesPage /> },
          { path: "services/service/:id/settings", element: <ServiceSettings /> },
          { path: "users", element: <UsersList /> },
          { path: "users/:id", element: <UserEditPage /> },
          { path: "users/new", element: <UsersCreatePage /> },
          {
            path: "officers",
            element: <OfficersList />,
            errorElement: <GenericInlineAlert />,
            loader: officersLoader,
            action: deleteOfficerAction
          },
          {
            path: "officers/new",
            element: <OfficerCreatePage />,
            action: putOfficerAction
          },
          {
            path: "officers/edit/:uuid",
            element: <OfficerUpdatePage />,
            errorElement: <GenericInlineAlert />,
            loader: officersLoader,
            action: putOfficerAction
          },
          { path: "organizations", element: <OrganizationsPage /> },
          { path: "notifications", element: <NotificationsList /> },
          { path: "notifications/new", element: <NotificationCreatePage /> },
          { path: "notifications/:id", element: <NotificationUpdatePage /> }
        ]
      }
    ]
  }
])

const Router = () => <RouterProvider router={router} />

export default Router
