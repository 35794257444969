import { useMutation, useQueryClient } from "@tanstack/react-query"
import { updateNotification } from "../api"

const useUpdateNotification = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateNotification,
    onSuccess: (data, variables) => {
      queryClient.setQueryData(["notification", variables.id?.toString()], data)
    }
  })
}

export default useUpdateNotification
