import { useEffect, useState } from "react"
import { Heading } from "suomifi-ui-components"
import { FormattedMessage } from "react-intl"
import { NotificationForm } from "./NotificationForm"
import { useCreateNotification } from "../hooks"
import { TimedToast } from "../components/TimedToast"
import { Notification } from "../types"

const createInitialNotification = () => {
  const endDate = new Date()
  endDate.setHours(endDate.getHours() + 1)
  return {
    text: "",
    startDate: new Date().getTime(),
    endDate: endDate.getTime(),
    channels: []
  }
}

const NotificationCreatePage = () => {
  const { data: createdNotification, mutate, status } = useCreateNotification()
  const [notification, setNotification] = useState<Notification>(createInitialNotification())

  useEffect(() => {
    if (createdNotification) {
      setNotification(createInitialNotification())
    }
  }, [createdNotification])

  return (
    <>
      <TimedToast status={status}>
        <FormattedMessage id="admin.info.tiedote.lisatty" />
      </TimedToast>
      <Heading variant="h1" className="mb-m">
        <FormattedMessage id="admin.header.lisaa.tiedote" />
      </Heading>
      <NotificationForm onSubmit={mutate} initialNotification={notification} />
    </>
  )
}

export { NotificationCreatePage }
