import { useForm, Controller } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { Button, TextInput } from "suomifi-ui-components"
import styles from "./UsersPage.module.scss"

import { useEffect } from "react"

type Props = {
  initialName?: string
  onSubmit: (name: string) => void
}

type FormValues = {
  name: string
}

const UserNameForm = ({ onSubmit, initialName = "" }: Props) => {
  const { formatMessage } = useIntl()

  const value: FormValues = {
    name: initialName
  }

  const { control, handleSubmit, reset } = useForm({ defaultValues: value })

  useEffect(() => {
    if (initialName) {
      reset({ name: initialName })
    }
  }, [initialName, reset])

  return (
    <form onSubmit={handleSubmit(data => onSubmit(data.name))} className="my-s">
      <div className="d-flex align-items-start gap-s">
        <Controller
          name="name"
          control={control}
          rules={{
            required: "admin.validation.required",
            pattern: { value: /\S+/, message: "admin.validation.required" },
            maxLength: { value: 100, message: "admin.validation.maxLength" }
          }}
          render={({ field: { onChange, ref, value, name }, fieldState: { error } }) => (
            <TextInput
              data-testid="user-form-name-input"
              ref={ref}
              name={name}
              value={value}
              labelText={formatMessage({ id: "admin.placeholder.kayttaja.nimi" })}
              visualPlaceholder={formatMessage({ id: "admin.common.insert" })}
              onChange={onChange}
              fullWidth
              status={error ? "error" : "default"}
              statusText={
                error && formatMessage({ id: error.message }, error.type === "maxLength" ? { value: 100 } : undefined)
              }
              style={{ maxWidth: 400 }}
            />
          )}
        />
        <div className={styles.saveUserButtonDiv}>
          <div>&nbsp;</div>
          <Button data-testid="save-user-name" type="submit">
            <FormattedMessage id="admin.button.tallenna" />
          </Button>
        </div>
      </div>
    </form>
  )
}

export { UserNameForm }
