import { Controller, FieldError, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  StatusText,
  Textarea,
  TextInput
} from "suomifi-ui-components"
import { ServiceCreate } from "../types"

type Props = {
  visible: boolean
  onCreate: (service: ServiceCreate) => void
  onCancel: () => void
}

const ServiceOption = {
  WEBAPI: { id: "webapi", labelKey: "admin.create.service.webapi.ids" },
  WEBAPI_REST: { id: "webapi_rest", labelKey: "admin.create.service.webapi.rest.ids" },
  XROAD: { id: "xroad", labelKey: "admin.create.service.xroad" }
}

const serviceOptions = [ServiceOption.WEBAPI, ServiceOption.WEBAPI_REST, ServiceOption.XROAD]

type ServiceCreateFormValues = {
  name: string
  description: string
  services: string[]
  xinstance: string
  memberClass: string
  memberCode: string
  subsystemCode: string
}

const ServiceCreateModal = ({ visible, onCreate, onCancel }: Props) => {
  const { formatMessage } = useIntl()

  const initialValues: ServiceCreateFormValues = {
    name: "",
    description: "",
    services: [],
    xinstance: "",
    memberClass: "",
    memberCode: "",
    subsystemCode: ""
  }

  const onSubmit = ({
    name,
    description,
    xinstance,
    memberClass,
    memberCode,
    subsystemCode,
    services
  }: ServiceCreateFormValues) =>
    onCreate({
      name,
      description,
      xinstance: services.includes(ServiceOption.XROAD.id) ? xinstance : "",
      memberClass: services.includes(ServiceOption.XROAD.id) ? memberClass : "",
      memberCode: services.includes(ServiceOption.XROAD.id) ? memberCode : "",
      subsystemCode: services.includes(ServiceOption.XROAD.id) ? subsystemCode : "",
      generateApiServiceIdentifier:
        services.includes(ServiceOption.WEBAPI.id) || services.includes(ServiceOption.WEBAPI_REST.id),
      generateApiSecret: services.includes(ServiceOption.WEBAPI.id),
      generateApiOauthSecret: services.includes(ServiceOption.WEBAPI.id),
      generateRestApiSecret: services.includes(ServiceOption.WEBAPI_REST.id)
    })

  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isValid, isSubmitting }
  } = useForm({ defaultValues: initialValues, mode: "onBlur" })
  const watchServices = watch("services")
  const xroadSelected = watchServices.includes(ServiceOption.XROAD.id)
  const xroadValues = watch(["xinstance", "memberClass", "memberCode", "subsystemCode"])
  const serviceIdentifier = xroadValues.join("_")
  const validateServiceIdentifier = () => serviceIdentifier.length <= 100

  const getStatus = (error: FieldError | undefined) => {
    return error ? "error" : "default"
  }

  const handleCancel = () => {
    reset()
    onCancel()
  }

  const getXRoadFieldStatusText = (error?: FieldError) => {
    if (error && error.type !== "validate") {
      return formatMessage({ id: error.message })
    }
  }

  return (
    <Modal appElementId="root" visible={visible} variant="default" onEscKeyDown={handleCancel}>
      <ModalContent>
        <ModalTitle>
          <FormattedMessage id="admin.modal.lisays.otsikko" />
        </ModalTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: "admin.validation.required",
              pattern: { value: /\S+/, message: "admin.validation.required" },
              minLength: { value: 2, message: "admin.validation.minLength" }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                {...field}
                data-testid="service-create-name-input"
                fullWidth
                className="mb-l"
                maxLength={50}
                labelText={formatMessage({ id: "admin.field.nimi" })}
                status={getStatus(error)}
                statusText={
                  error && formatMessage({ id: error.message }, error.type === "minLength" ? { value: 2 } : undefined)
                }
              />
            )}
          />

          <Textarea
            {...register("description", {
              required: "admin.validation.required",
              pattern: {
                value: /\S+/,
                message: "admin.validation.required"
              }
            })}
            data-testid="service-create-description-input"
            fullWidth
            className="mb-l"
            maxLength={1000}
            rows={4}
            labelText={formatMessage({ id: "admin.field.kuvaus" })}
            status={getStatus(errors["description"])}
            statusText={errors.description && formatMessage({ id: errors.description.message })}
          />
          <CheckboxGroup labelText={formatMessage({ id: "admin.create.service" })}>
            {serviceOptions.map(({ id, labelKey }) => (
              <Controller
                key={id}
                name="services"
                control={control}
                rules={{ required: "admin.validation.required" }}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                  <Checkbox
                    data-testid={`service-create-fi.vm.kapa.rova:roles-auths-admin-ui:jar:1.0-SNAPSHOT-checkbox`}
                    checked={value.includes(id)}
                    onClick={({ checkboxState }) => {
                      const services = checkboxState ? value.concat(id) : value.filter(s => s !== id)
                      onChange(services)
                      onBlur()
                    }}
                    status={getStatus(error)}
                  >
                    <FormattedMessage id={labelKey} />
                  </Checkbox>
                )}
              />
            ))}
          </CheckboxGroup>
          <StatusText status="error" className={errors.services ? "mt-xs" : undefined}>
            {errors.services && <FormattedMessage id={errors.services.message} />}
          </StatusText>
          {xroadSelected && (
            <div className="mt-l">
              <div className="mb-m">
                <StatusText status="error">
                  {!validateServiceIdentifier() && <FormattedMessage id="admin.error.tunniste.liika.pitka" />}
                </StatusText>
              </div>
              <div className="d-flex flex-wrap flex-md-nowrap gap-l gap-md-m mb-l">
                <Controller
                  name="xinstance"
                  control={control}
                  rules={{
                    required: {
                      value: xroadSelected,
                      message: "admin.validation.required"
                    },
                    pattern: {
                      value: /^[0-9A-Za-z.-]+$/,
                      message: "admin.validation.pattern"
                    },
                    validate: validateServiceIdentifier
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      data-testid="service-create-xinstance-input"
                      fullWidth
                      className="w-50"
                      maxLength={87}
                      labelText={formatMessage({ id: "admin.field.xinstance" })}
                      visualPlaceholder={formatMessage({ id: "admin.placeholder.xinstance" })}
                      status={getStatus(error)}
                      statusText={getXRoadFieldStatusText(error)}
                    />
                  )}
                />
                <Controller
                  name="memberClass"
                  control={control}
                  rules={{
                    required: {
                      value: xroadSelected,
                      message: "admin.validation.required"
                    },
                    pattern: {
                      value: /^[0-9A-Za-z.-]+$/,
                      message: "admin.validation.pattern"
                    },
                    validate: validateServiceIdentifier
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      data-testid="service-create-memberClass-input"
                      fullWidth
                      className="w-50"
                      maxLength={87}
                      labelText={formatMessage({ id: "admin.field.memberClass" })}
                      visualPlaceholder={formatMessage({ id: "admin.placeholder.memberClass" })}
                      status={getStatus(error)}
                      statusText={getXRoadFieldStatusText(error)}
                    />
                  )}
                />
                <Controller
                  name="memberCode"
                  control={control}
                  rules={{
                    required: {
                      value: xroadSelected,
                      message: "admin.validation.required"
                    },
                    pattern: {
                      value: /^[0-9]{7}-[0-9]{1}$/,
                      message: "admin.validation.pattern"
                    },
                    validate: validateServiceIdentifier
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      data-testid="service-create-memberCode-input"
                      fullWidth
                      maxLength={9}
                      labelText={formatMessage({ id: "admin.field.memberCode" })}
                      visualPlaceholder={formatMessage({ id: "admin.placeholder.memberCode" })}
                      status={getStatus(error)}
                      statusText={getXRoadFieldStatusText(error)}
                    />
                  )}
                />
              </div>
              <Controller
                name="subsystemCode"
                control={control}
                rules={{
                  required: {
                    value: xroadSelected,
                    message: "admin.validation.required"
                  },
                  pattern: {
                    value: /^[0-9A-Za-z._-]+$/,
                    message: "admin.validation.pattern"
                  },
                  validate: validateServiceIdentifier
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    {...field}
                    data-testid="service-create-subsystemCode-input"
                    fullWidth
                    maxLength={87}
                    labelText={formatMessage({ id: "admin.field.subsystemCode" })}
                    visualPlaceholder={formatMessage({ id: "admin.placeholder.subsystemCode" })}
                    status={getStatus(error)}
                    statusText={getXRoadFieldStatusText(error)}
                  />
                )}
              />
            </div>
          )}
        </form>
      </ModalContent>
      <ModalFooter>
        <Button
          data-testid="service-create-add-button"
          disabled={!isValid || isSubmitting}
          onClick={() => handleSubmit(onSubmit)()}
        >
          <FormattedMessage id="admin.button.lisaa" />
        </Button>
        <Button data-testid="service-create-cancel-button" variant="secondary" onClick={handleCancel}>
          <FormattedMessage id="admin.button.peruuta" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { ServiceCreateModal }
