import { Heading } from "suomifi-ui-components"
import { ReactComponent as SfiIcon } from "./Sfi.svg"
import { FormattedMessage } from "react-intl"

export const AdminBrand = () => {
  return (
    <div className="d-flex align-items-center gap-xs">
      <SfiIcon height={40} />
      <Heading variant="h2" color="brandBase" className="ms-xxs fw-normal">
        <FormattedMessage id="admin.site.title" />
      </Heading>
    </div>
  )
}
