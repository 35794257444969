import { useQuery } from "@tanstack/react-query"
import { getLocalization } from "../api"
import { mapValues } from "../utils/object"

const useLocalization = (locale: string, showTranslationKeys: boolean) => {
  return useQuery({
    queryKey: ["localization", locale, showTranslationKeys],
    queryFn: async () => {
      const localization = await getLocalization(locale)
      if (showTranslationKeys) {
        return mapValues(localization, (_value, key) => key)
      } else {
        return localization
      }
    }
  })
}

export default useLocalization
