import { useParams } from "react-router-dom"
import { useUser } from "../hooks"
import { UserPage } from "./UserPage"

const UserEditPage = () => {
  const { id } = useParams()
  const { data: user } = useUser(id)

  if (!user) return null

  return <UserPage initialUser={user} />
}

export { UserEditPage }
