import { useMemo } from "react"
import { useForm, Controller } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { Button, SingleSelect, Modal, ModalContent, ModalTitle, ModalFooter } from "suomifi-ui-components"

import type { AuthorizationRequest, User } from "../types"

type Props = {
  visible: boolean
  user?: User
  singleSelectItems: {
    uniqueItemId: string
    labelText: string
  }[]
  onCreate: (authorization: AuthorizationRequest) => void
  onCancel: () => void
}

type SingleSelectFormValue = {
  labelText: string
  serviceUuid: string
}

const UserAuthorizationModal = ({ visible, onCreate, onCancel, singleSelectItems }: Props) => {
  const orderedItems = useMemo(() => {
    if (singleSelectItems) {
      return singleSelectItems.sort((a, b) => a.labelText.localeCompare(b.labelText))
    }
    return []
  }, [singleSelectItems])

  const initialValue: SingleSelectFormValue = {
    labelText: "",
    serviceUuid: ""
  }

  const { formatMessage } = useIntl()

  const toAuthorizationRequest = (uuid: string): AuthorizationRequest => {
    return {
      roleName: "RULEMGMT",
      serviceUuid: uuid,
      user: null
    }
  }

  const onSubmit = ({ serviceUuid }: SingleSelectFormValue) => {
    onCreate(toAuthorizationRequest(serviceUuid))
  }

  const { control, reset, handleSubmit } = useForm({ defaultValues: initialValue })

  const handleCancel = () => {
    reset()
    onCancel()
  }

  return (
    <Modal appElementId="root" visible={visible} variant="default" onEscKeyDown={handleCancel}>
      <ModalContent>
        <ModalTitle>
          <FormattedMessage id="admin.lisaa.saantojenhallintaoikeus" />
        </ModalTitle>
        <form>
          <Controller
            name="serviceUuid"
            control={control}
            rules={{ required: "admin.validation.required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SingleSelect
                data-testid="user-authorization-modal-single-select"
                ariaOptionsAvailableText=""
                clearButtonLabel=""
                items={orderedItems}
                selectedItem={orderedItems.find(item => value === item.uniqueItemId)}
                onItemSelectionChange={selected => onChange(selected?.uniqueItemId)}
                labelText={formatMessage({ id: "admin.filter.services" })}
                noItemsText={formatMessage({ id: "admin.common.input.filter.no.results" })}
                status={error ? "error" : "default"}
                visualPlaceholder={formatMessage({ id: "admin.common.insert" })}
              />
            )}
          />
        </form>
      </ModalContent>
      <ModalFooter>
        <Button
          data-testid="user-authorization-modal-save-button"
          type="submit"
          onClick={() => handleSubmit(onSubmit)()}
        >
          <FormattedMessage id="admin.button.tallenna" />
        </Button>
        <Button data-testid="user-authorization-modal-cancel-button" variant="secondary" onClick={handleCancel}>
          <FormattedMessage id="admin.button.peruuta" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { UserAuthorizationModal }
