import { NavLink } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import css from "./NavBar.module.scss"

const navLinkCssFn = ({ isActive }: { isActive: boolean }) => {
  return isActive ? `${css.navLink} ${css.navLinkActive}` : css.navLink
}

const NavBar = ({ authorizations }: { authorizations: string[] }) => {
  return (
    <div className={css.navContainer}>
      <nav className={`container ${css.nav}`}>
        {(authorizations.includes("SERVICEMGMT") || authorizations.includes("RULEMGMT")) && (
          <NavLink data-testid="nav-services" className={navLinkCssFn} to="/services">
            <FormattedMessage id="admin.header.services" />
          </NavLink>
        )}
        {authorizations.includes("USERMGMT") && (
          <NavLink data-testid="nav-users" className={navLinkCssFn} to="/users">
            <FormattedMessage id="admin.header.users" />
          </NavLink>
        )}
        {authorizations.includes("OFFICERADMIN") && (
          <NavLink data-testid="nav-officers" className={navLinkCssFn} to="/officers">
            <FormattedMessage id="admin.header.officers" />
          </NavLink>
        )}
        {authorizations.includes("SERVICEMGMT") && (
          <NavLink data-testid="nav-organizations" className={navLinkCssFn} to="/organizations">
            <FormattedMessage id="admin.organizations.heading" />
          </NavLink>
        )}
        {authorizations.includes("NOTIFICATIONMGMT") && (
          <NavLink data-testid="nav-hairiotiedotteet" className={navLinkCssFn} to="/notifications">
            <FormattedMessage id="admin.header.hairiotiedotteet" />
          </NavLink>
        )}
      </nav>
    </div>
  )
}

export { NavBar }
