import { Fragment } from "react"
import { FormattedDate, FormattedMessage } from "react-intl"
import { Notification } from "../types"
import { IconEdit } from "suomifi-ui-components"
import { NavButton } from "../components/NavButton"

const NotificationTable = ({ notifications }: { notifications: Notification[] }) => {
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th>
            <FormattedMessage id="admin.notifications.publishTime" />
          </th>
          <th className="px-l">
            <FormattedMessage id="admin.notifications.notificationContent" />
          </th>
          <th>
            <FormattedMessage id="admin.button.muokkaa" />
          </th>
        </tr>
      </thead>
      <tbody>
        {notifications.map(notification => (
          <tr key={notification.id}>
            <td className="text-nowrap">
              <FormattedDate value={notification.startDate} />
              <> - </>
              <FormattedDate value={notification.endDate} />
            </td>
            <td className="text-break w-100 px-l">
              {notification.text.split("\n").map((line, index) => (
                <Fragment key={index}>
                  {index > 0 && <br />}
                  {line}
                </Fragment>
              ))}
            </td>
            <td className="text-end">
              <NavButton
                data-testid={`notifications-table-nav-${notification.id}`}
                to={`/notifications/${notification.id}`}
                variant="secondaryNoBorder"
              >
                <IconEdit />
              </NavButton>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export { NotificationTable }
