import { InlineAlert } from "suomifi-ui-components"
import { FormattedMessage } from "react-intl"
import { LogoutButton } from "../components/LogoutButton"

const UnauthorizedPage = () => {
  return (
    <div className="container my-l">
      <InlineAlert status="error">
        <FormattedMessage id="admin.error.ei.kayttooikeuksia" />
      </InlineAlert>
      <div className="my-m">
        <LogoutButton>
          <FormattedMessage id="admin.logout" />
        </LogoutButton>
      </div>
    </div>
  )
}

export { UnauthorizedPage }
