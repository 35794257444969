import { NotificationForm } from "./NotificationForm"
import { Heading, InlineAlert } from "suomifi-ui-components"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"
import { useNotification, useUpdateNotification } from "../hooks"
import { TimedToast } from "../components/TimedToast"

const NotificationUpdatePage = () => {
  const { id } = useParams()
  const { data: notification } = useNotification(id)
  const { status, mutate, isError: updateError } = useUpdateNotification()

  if (typeof notification == "string") {
    return (
      <InlineAlert status="error">
        <FormattedMessage id="admin.error.tiedotetta.ei.loytynyt" />
      </InlineAlert>
    )
  }

  if (!notification) {
    return null
  }

  return (
    <>
      <TimedToast status={status}>
        <FormattedMessage id="admin.info.tiedote.paivitetty" />
      </TimedToast>
      <Heading variant="h1">
        <FormattedMessage id="admin.header.muokkaa.tiedotetta" />
      </Heading>
      <div className="row">
        <div className="col col-md-10">
          <NotificationForm initialNotification={notification} onSubmit={mutate} />
        </div>
      </div>
      {updateError && (
        <InlineAlert status="error" className="mt-m">
          <FormattedMessage id="admin.error.tiedotejulkaisu.epaonnistui" />
        </InlineAlert>
      )}
    </>
  )
}

export { NotificationUpdatePage }
