import { Notification } from "../types"
import { useMemo } from "react"
import { NotificationTable } from "./NotificationTable"

const HistoryNotifications = ({ notifications }: { notifications: Notification[] }) => {
  const historyNotifications = useMemo(() => {
    const now = new Date().getTime()
    return notifications
      .filter(notification => new Date(notification.endDate).getTime() < now)
      .sort((a, b) => a.startDate - b.startDate)
      .reverse()
  }, [notifications])

  return <NotificationTable notifications={historyNotifications} />
}

export { HistoryNotifications }
