import { Service, User } from "../types"
import { JSONPath } from "jsonpath-plus"

export const filterByTerm = (term: string | undefined, toBeFiltered: object): boolean => {
  if (!term || term.length === 0) {
    return true
  }
  return Object.values(toBeFiltered).some(member => {
    if (typeof member === "string" || member instanceof String) {
      return term && member.toLowerCase().includes(term.trim().toLowerCase())
    }
    return false
  })
}

export const flattenServiceForDeepSearch = (service: Service) => {
  return JSONPath({ path: "$.*", json: service }).concat(
    JSONPath({
      path: "$.serviceConfig[hpaIssueUris,ypaIssueUris,yyaIssueUris,yhaIssueUris,hukoIssueUris].*",
      json: service
    })
      .concat(JSONPath({ path: "$.localizedContent.[displayName,url]", json: service }))
      .concat(JSONPath({ path: "$.organization[services,names].*", json: service }))
  )
}

export const flattenUserForDeepSearch = (user: User) => {
  const identifier = user.userIdentities.map(identity => {
    return identity.userIdentifier
  })
  const authorizations = user.userAuthorizations.map(auth => {
    return auth.roleName
  })

  return flattenDeep([user.uuid, user.name, identifier, authorizations])
}

type NestedArray<T> = Array<T | NestedArray<T>>

const flattenDeep = (array: NestedArray<string>): Array<string> => {
  return array.reduce(
    (acc: string[], val: string | NestedArray<string>) =>
      Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val),
    []
  )
}
