import { Button, Modal, ModalContent, ModalFooter, ModalTitle } from "suomifi-ui-components"
import { FormattedMessage } from "react-intl"

type Props = {
  onConfirm: () => void
  onCancel: () => void
  visible: boolean
  loading?: boolean
}

const SaveModal = ({ onConfirm, onCancel, visible, loading }: Props) => {
  return (
    <Modal visible={visible} appElementId="root">
      <ModalContent>
        <ModalTitle>
          <FormattedMessage id="admin.modal.tallennus.header" />
        </ModalTitle>
        <FormattedMessage id="admin.modal.tallennus.otsikko" />
      </ModalContent>
      <ModalFooter>
        <Button data-testid="confirm-save-yes-button" disabled={loading} onClick={onConfirm}>
          <FormattedMessage id="admin.button.kylla" />
        </Button>
        <Button data-testid="confirm-save-no-button" disabled={loading} onClick={onCancel} variant="secondary">
          <FormattedMessage id="admin.button.ei" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { SaveModal }
