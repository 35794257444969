import { Control, Controller } from "react-hook-form"
import { IntlShape } from "react-intl"
import { MultiSelect } from "suomifi-ui-components"
import { ServiceSettingsFilterValue, ServicesFilterValue } from "./servicesFilter.types"

const serviceSettings: { valueKey: ServiceSettingsFilterValue; labelKey: string }[] = [
  { valueKey: "includeReasons", labelKey: "admin.checkbox.hylkaysperusteet" },
  { valueKey: "allowMultiSelection", labelKey: "admin.checkbox.monivalinta" },
  { valueKey: "useNameSeparator", labelKey: "admin.checkbox.use.name.separator" },
  { valueKey: "includeOrganizationIdHpa", labelKey: "admin.checkbox.organisaatiotunniste.hpa" },
  { valueKey: "includeForeignPersons", labelKey: "admin.checkbox.ulkomaalaiset.henkilot" },
  { valueKey: "includeInfoPrincipals", labelKey: "admin.checkbox.include.info.principals" },
  { valueKey: "allowEmptyVTJRules", labelKey: "admin.checkbox.tyhjat.vtj.saannot" },
  { valueKey: "includeOrganizationIdYpa", labelKey: "admin.checkbox.organisaatiotunniste.ypa" },
  { valueKey: "includeForeignCompanies", labelKey: "admin.checkbox.ulkomaalaiset.yritykset" }
]

export const ServiceSettingsFilter = ({
  control,
  intl: { formatMessage },
  labelKey,
  valueKey,
  disabledOptions
}: {
  control: Control<ServicesFilterValue>
  intl: IntlShape
  labelKey: string
  valueKey: "enabledSettings" | "disabledSettings"
  disabledOptions: ServiceSettingsFilterValue[]
}) => (
  <Controller
    name={valueKey}
    control={control}
    render={({ field: { value, onChange, onBlur } }) => (
      <MultiSelect
        data-testid={`services-filter-${valueKey}`}
        className="w-100"
        items={serviceSettings.map(({ valueKey, labelKey }) => ({
          uniqueItemId: valueKey,
          labelText: formatMessage({ id: labelKey }),
          disabled: disabledOptions.includes(valueKey)
        }))}
        selectedItems={value.map(id => ({
          uniqueItemId: id,
          labelText: formatMessage({
            id: serviceSettings.find(setting => setting.valueKey === id)?.labelKey
          })
        }))}
        onItemSelect={uniqueItemId => {
          const ids = !value.includes(uniqueItemId as ServiceSettingsFilterValue)
            ? value.concat(uniqueItemId as ServiceSettingsFilterValue)
            : value.filter(id => id !== uniqueItemId)
          onChange(ids)
        }}
        onBlur={onBlur}
        labelText={formatMessage({ id: labelKey })}
        noItemsText={formatMessage({ id: "admin.common.input.filter.no.results" })}
        ariaSelectedAmountText=""
        ariaOptionsAvailableText=""
        ariaOptionChipRemovedText=""
        chipListVisible
      />
    )}
  />
)
