import { InlineAlert } from "suomifi-ui-components"
import { FormattedMessage, useIntl } from "react-intl"

const GenericInlineAlert = () => {
  const { formatMessage } = useIntl()
  return (
    <InlineAlert status="error" labelText={formatMessage({ id: "admin.common.error.title" })}>
      <FormattedMessage id="admin.common.error.text" />
    </InlineAlert>
  )
}

export default GenericInlineAlert
