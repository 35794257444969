import { useMutation, useQueryClient } from "@tanstack/react-query"
import { createOrganization } from "../api"
import { Organization } from "../types"
import { compareOrganizations } from "../utils/organizations"

const useCreateOrganisation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createOrganization,
    onSuccess: data => {
      queryClient.setQueryData<Organization[]>(["organizations"], old =>
        old ? [...old, data].sort(compareOrganizations) : undefined
      )
    }
  })
}

export default useCreateOrganisation
