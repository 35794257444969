import { Button, IconRemove, Modal, ModalContent, ModalFooter, ModalTitle } from "suomifi-ui-components"
import { FormattedMessage, useIntl } from "react-intl"
import { useState } from "react"
import useDeleteOrganization from "../hooks/useDeleteOrganization"
import GenericInlineAlert from "../components/GenericInlineAlert"
import { Organization } from "../types"

type Props = {
  organization: Organization
  mutation: ReturnType<typeof useDeleteOrganization>
}

const OrganizationDeleteButton = ({ organization, mutation }: Props) => {
  const [visible, setVisible] = useState(false)

  const intl = useIntl()

  const close = () => {
    setVisible(false)
    mutation.reset()
  }

  const blocked = organization.services.length > 0

  return (
    <>
      <Button
        aria-label={intl.formatMessage({ id: "admin.button.poista" })}
        icon={<IconRemove />}
        variant="secondaryNoBorder"
        data-testid="org-delete-button"
        onClick={() => setVisible(true)}
      />
      <Modal visible={visible} appElementId="root" scrollable={blocked}>
        {blocked ? (
          <OrganizationDeleteBlockedModal services={organization.services} onClose={close} />
        ) : (
          <OrganizationDeleteConfirmModal organization={organization} onClose={close} mutation={mutation} />
        )}
      </Modal>
    </>
  )
}

const OrganizationDeleteConfirmModal = ({
  organization,
  onClose,
  mutation
}: {
  organization: Organization
  onClose: () => void
  mutation: ReturnType<typeof useDeleteOrganization>
}) => {
  const { mutate, isPending, isError } = mutation
  return (
    <>
      <ModalContent>
        <ModalTitle>
          <FormattedMessage id="admin.organizations.delete.heading" />
        </ModalTitle>
        <p>
          <FormattedMessage id="admin.organizations.delete.confirm_text" values={{ name: organization.names.fi }} />
        </p>
      </ModalContent>
      <ModalFooter>
        {isError && <GenericInlineAlert />}
        <Button onClick={() => mutate(organization.id)} disabled={isPending} data-testid="org-delete-confirm-button">
          <FormattedMessage id="admin.button.kylla" />
        </Button>
        <Button variant="secondary" onClick={onClose} data-testid="org-delete-cancel-button">
          <FormattedMessage id="admin.button.peruuta" />
        </Button>
      </ModalFooter>
    </>
  )
}

const OrganizationDeleteBlockedModal = ({ services, onClose }: { services: string[]; onClose: () => void }) => {
  return (
    <>
      <ModalContent>
        <ModalTitle>
          <FormattedMessage id="admin.organizations.delete.heading" />
        </ModalTitle>
        <>
          <p>
            <FormattedMessage id="admin.organizations.delete.text" values={{ count: services.length }} />
          </p>
          <ul>
            {services.map(service => (
              <li key={service}>{service}</li>
            ))}
          </ul>
        </>
      </ModalContent>
      <ModalFooter>
        <Button onClick={onClose} data-testid="org-delete-close-button">
          <FormattedMessage id="common.close" />
        </Button>
      </ModalFooter>
    </>
  )
}

export default OrganizationDeleteButton
