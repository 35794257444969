import { FormattedMessage } from "react-intl"
import { Heading } from "suomifi-ui-components"
import { LoginButton } from "../components/LoginButton"
import useRootContext from "../hooks/useRootContext"

const LandingPage = () => {
  const { locale } = useRootContext()

  return (
    <>
      <div className="container my-l">
        <Heading variant="h2">
          <FormattedMessage id="admin.landingpage.title" />
        </Heading>
        <p>
          <FormattedMessage id="admin.landingpage.description" />
        </p>
        <LoginButton lang={locale} />
      </div>
    </>
  )
}

export { LandingPage }
