import { useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import {
  ActionMenu,
  ActionMenuItem,
  Button,
  Heading,
  IconDisabled,
  IconDownload,
  IconEdit,
  IconSettings,
  Text
} from "suomifi-ui-components"
import { TimedToast } from "../components/TimedToast"
import { useAuthorizations, useCreateService, useServices } from "../hooks"
import { Auth, OperationStatus, Service, PublicInfo } from "../types"
import { formatDate } from "../utils/date"
import { ServiceCreateModal } from "./ServiceCreateModal"
import { ServicesFilter } from "./ServicesFilter"
import { ErrorNotification } from "../components/ErrorNotification"
import { useSessionStorage } from "react-use"
import { supportedLanguages } from "./ServiceSettings"
import styles from "./ServicesPage.module.scss"

const ServicesPage = () => {
  const [filtersActive, setFiltersActive] = useState(false)
  const [filteredServices, setFilteredServices] = useState<Service[]>([])
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [showPublicInfo, setShowPublicInfo] = useSessionStorage("showPublicInfo", false)

  const { data: services } = useServices()
  const { data: createdService, mutate: createService, isError: createError, reset: createReset } = useCreateService()
  const authorizations = useAuthorizations()
  const intl = useIntl()
  const navigate = useNavigate()

  const { state } = useLocation()
  const status = state as OperationStatus

  const isAdmin = authorizations.includes(Auth.SERVICEMGMT)

  const orderedServices = useMemo(() => {
    if (services) {
      return services.sort((a, b) => a.name.localeCompare(b.name))
    }
    return []
  }, [services])

  if (createdService) {
    return <Navigate to={`/services/service/${createdService.id}/settings`} />
  }

  const renderHeading = () => {
    return (
      <div className="d-flex flex-wrap flex-md-nowrap flex-column flex-md-row justify-content-between align-items-md-center gap-m mb-m">
        <Heading variant="h1">
          <FormattedMessage id="admin.header.services" />
        </Heading>
        {isAdmin && (
          <>
            <Button data-testid="service-list-add-button" onClick={() => setOpenCreateModal(true)}>
              <FormattedMessage id="admin.button.lisaa.palvelu" />
            </Button>
            <ServiceCreateModal
              visible={openCreateModal}
              onCreate={createService}
              onCancel={() => setOpenCreateModal(false)}
            />
          </>
        )}
      </div>
    )
  }

  const renderServices = () => {
    return (
      <>
        <div className="gap-m">
          <div className={styles.servicesCountDiv}>
            <Text variant="bold" aria-live="polite" aria-atomic="true">
              {filtersActive ? (
                <FormattedMessage
                  id="admin.field.services.count"
                  values={{ count: filteredServices.length, total: orderedServices.length }}
                />
              ) : (
                <FormattedMessage id="admin.field.palvelua" values={{ count: filteredServices.length }} />
              )}
            </Text>
          </div>
          <div className="d-flex flex-wrap flex-md-nowrap flex-column flex-md-row justify-content-between align-items-md-center gap-m mb-m">
            <div>
              <ActionMenu
                className={styles.servicesActionMenu}
                buttonText={intl.formatMessage({ id: "admin.services.views.buttonText" })}
              >
                <ActionMenuItem disabled={!showPublicInfo} onClick={() => setShowPublicInfo(false)}>
                  <FormattedMessage id="admin.services.views.services" />
                </ActionMenuItem>
                <ActionMenuItem disabled={showPublicInfo} onClick={() => setShowPublicInfo(true)}>
                  <FormattedMessage id="admin.services.views.publicInfo" />
                </ActionMenuItem>
              </ActionMenu>
            </div>
            <div>
              {isAdmin && (
                <form action="/admin/service/report">
                  <input type="hidden" name="lang" value={intl.locale} />
                  <Button
                    data-testid="services-download-report-button"
                    variant="secondaryNoBorder"
                    icon={<IconDownload />}
                    type="submit"
                    fullWidth
                  >
                    <FormattedMessage id="admin.link.servicereport" />
                  </Button>
                </form>
              )}
            </div>
          </div>
        </div>
        {showPublicInfo ? renderPublicInfoTable() : renderServicesTable()}
      </>
    )
  }

  const publicInfo = (service: Service): PublicInfo[] => {
    return supportedLanguages.map(lang => {
      const content = service.localizedContent?.[lang]
      const organization = service.organization?.names[lang]
      return {
        id: service.id,
        lang,
        organization,
        service: content?.displayName,
        url: content?.url
      }
    })
  }

  const renderPublicInfoTable = () => {
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="admin.field.kieli" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="admin.field.organisaatio" />
            </th>
            <th>
              <FormattedMessage id="admin.field.palvelu" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="admin.field.verkko-osoite" />
            </th>
            {isAdmin && (
              <th className="text-center">
                <FormattedMessage id="admin.button.hallinnoi" />
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {filteredServices
            .flatMap(service => publicInfo(service))
            .map(publicInfo => (
              <tr
                key={`${publicInfo.lang}-${publicInfo.id}`}
                className={publicInfo.lang === supportedLanguages[0] ? styles.serviceFirstPublicInfoRow : ""}
              >
                <td className="text-uppercase">{publicInfo.lang}</td>
                <td className="d-none d-md-table-cell text-break">{publicInfo.organization}</td>
                <td className="text-break">{publicInfo.service}</td>
                <td className="d-none d-md-table-cell text-break">{publicInfo.url}</td>
                {isAdmin ? (
                  publicInfo.lang === supportedLanguages[0] ? (
                    <td className="text-center">
                      <Button
                        aria-label={intl.formatMessage({ id: "admin.button.hallinnoi" })}
                        data-testid="services-settings-button"
                        onClick={() => navigate(`/services/service/${publicInfo.id}/settings`)}
                        variant="secondaryNoBorder"
                        icon={<IconSettings />}
                      />
                    </td>
                  ) : (
                    <td />
                  )
                ) : null}
              </tr>
            ))}
        </tbody>
      </table>
    )
  }

  const renderServicesTable = () => {
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="admin.field.palvelu" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="admin.field.kuvaus" />
            </th>
            <th>
              <FormattedMessage id="admin.field.modified" />
            </th>
            <th>
              <FormattedMessage id="admin.button.muokkaa" />
            </th>
            {isAdmin && (
              <th>
                <FormattedMessage id="admin.button.hallinnoi" />
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {filteredServices.map(service => (
            <tr key={service.id}>
              <td>
                {service.name}
                {service.active || (
                  <span className={styles.serviceStatusSpan}>
                    <IconDisabled ariaLabel={intl.formatMessage({ id: "admin.info.ei_kaytossa" })} />
                  </span>
                )}
              </td>
              <td className="d-none d-md-table-cell text-break">{service.description}</td>
              <td>{formatDate(service.modified, intl.locale)}</td>
              <td>
                <Button
                  aria-label={intl.formatMessage({ id: "admin.button.muokkaa" })}
                  data-testid="services-edit-button"
                  onClick={() => navigate(`/services/service/edit/${service.uuid}`)}
                  variant="secondaryNoBorder"
                  icon={<IconEdit />}
                />
              </td>
              {isAdmin && (
                <td>
                  <Button
                    aria-label={intl.formatMessage({ id: "admin.button.hallinnoi" })}
                    data-testid="services-settings-button"
                    onClick={() => navigate(`/services/service/${service.id}/settings`)}
                    variant="secondaryNoBorder"
                    icon={<IconSettings />}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  const renderNoServices = () => {
    return (
      <div className="mt-m">
        <FormattedMessage id="admin.info.ei_palveluita" />
      </div>
    )
  }

  const renderNotifications = () => {
    return (
      <>
        {createError && (
          <ErrorNotification id="create-error-notification" onClose={createReset}>
            <FormattedMessage id="admin.error.create.failed" />
          </ErrorNotification>
        )}
        <TimedToast status={status?.deleteStatus}>
          <FormattedMessage id="admin.info.poisto.onnistui" />
        </TimedToast>
      </>
    )
  }

  return (
    <>
      {renderNotifications()}
      {renderHeading()}
      <ServicesFilter
        services={orderedServices}
        setFilteredServices={setFilteredServices}
        setFiltersActive={setFiltersActive}
      />
      {services && services.length > 0 ? renderServices() : renderNoServices()}
    </>
  )
}

export { ServicesPage }
