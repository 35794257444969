import { useNavigate } from "react-router-dom"
import { Button, ButtonProps } from "suomifi-ui-components"

type Props = {
  to: string
} & Omit<ButtonProps, "onClick">

export const NavButton = ({ to, children, ...props }: Props) => {
  const navigate = useNavigate()
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Button onClick={() => navigate(to)} {...(props as any)}>
      {children}
    </Button>
  )
}
