import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { fi, sv } from "date-fns/locale"
import { registerLocale } from "react-datepicker"
import Router from "./router/Router"

registerLocale("fi", fi)
registerLocale("sv", sv)

const queryClient = new QueryClient()

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  )
}

export { App }
