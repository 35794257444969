import { ReactNode } from "react"
import { Checkbox } from "suomifi-ui-components"
import { FormattedMessage } from "react-intl"
import { NormalizedRule } from "../../types"

type Props = {
  rule: NormalizedRule
  onClick: (rule: NormalizedRule) => void
  children: ReactNode
  disabled?: boolean
}

const ServiceRule = ({ rule, onClick, children, disabled }: Props) => {
  const { type, ruleId, selected, fixed } = rule

  return (
    <>
      <Checkbox
        data-testid={`service-rule-${type}_${ruleId}`}
        checked={selected}
        disabled={fixed || disabled}
        onClick={() => onClick(rule)}
      >
        {ruleId} <FormattedMessage id={ruleId} />
      </Checkbox>
      {selected && <div className="ms-l">{children}</div>}
    </>
  )
}

export default ServiceRule
