import { FormattedMessage } from "react-intl"
import { OfficerForm } from "./OfficerForm"
import { Heading } from "suomifi-ui-components"

const OfficerCreatePage = () => {
  return (
    <>
      <Heading variant="h1" className="mb-m">
        <FormattedMessage id="admin.header.add.officer" />
      </Heading>
      <OfficerForm />
    </>
  )
}

export { OfficerCreatePage }
