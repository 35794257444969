import { useMutation, useQueryClient } from "@tanstack/react-query"
import { deleteAuthorization } from "../api"

const useDeleteAuthorization = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteAuthorization,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] })
    }
  })
}

export default useDeleteAuthorization
