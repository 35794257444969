import { useEffect, useMemo, useState } from "react"
import { Controller, FieldError, useFieldArray, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import {
  Button,
  IconCopy,
  IconEdit,
  IconRemove,
  Checkbox,
  Heading,
  Label,
  MultiSelect,
  Paragraph,
  RadioButton,
  RadioButtonGroup,
  SingleSelect,
  SingleSelectData,
  StatusText,
  Text,
  Textarea,
  TextInput,
  ToggleInput
} from "suomifi-ui-components"
import { Divider } from "../components/Divider"
import { SaveModal } from "../components/SaveModal"
import { TimedStatusText } from "../components/TimedStatusText"
import { TimedToast } from "../components/TimedToast"
import { RemoveModal } from "../components/RemoveModal"
import {
  useAuthorizations,
  useConfirmSave,
  useService,
  useServiceAllApis,
  useServiceAllIssues,
  useUpdateService,
  useUsersForService,
  useDeleteService
} from "../hooks"
import { Auth, Issue, LocalizedContent, Organization, ServiceType } from "../types"
import { ErrorNotification } from "../components/ErrorNotification"
import styles from "./ServicesPage.module.scss"
import useOrganizations from "../hooks/useOrganizations"
import OrganizationEditModal from "../Organizations/OrganizationEditModal"
import OrganizationAddModal from "../Organizations/OrganizationAddModal"
import { mapValues } from "../utils/object"
import { NavigationBlocker } from "../components/NavigationBlocker"

export const supportedLanguages = ["fi", "sv", "en"]
const initialLocalizedContent = Object.fromEntries(
  supportedLanguages.map(lang => [lang, { langCode: lang, displayName: "", url: "" }])
)

type ServiceConfig = {
  enabledApis: string[]
  hpaIssueUris: string[]
  ypaIssueUris: string[]
  yyaIssueUris: string[]
  yhaIssueUris: string[]
  hukoIssueUris: string[]
  includeReasons: boolean
  includeOrganizationIdYpa: boolean
  includeOrganizationIdHpa: boolean
  includeForeignCompanies: boolean
  includeForeignPersons: boolean
  includeInfoPrincipals: boolean
  allowMultiSelection: boolean
  allowEmptyVTJRules: boolean
  useNameSeparator: boolean
  nameSeparator: string
}

type ServiceSettingsFormValues = {
  name: string
  description: string
  active: boolean
  xroadSelected: boolean
  xinstance: string
  memberClass: string
  memberCode: string
  subsystemCode: string
  generateApiServiceIdentifier: boolean
  generateApiSecret: boolean
  generateApiOauthSecret: boolean
  generateRestApiSecret: boolean
  apiServiceIdentifier: string | null
  apiSecret: string | null
  apiOauthSecret: string | null
  restApiSecret: string | null
  apiOauthRedirectUrls: string[]
  localizedContent: {
    [lang: string]: {
      langCode: string
      displayName: string
      url: string
    }
  }
  serviceType?: ServiceType
  organizationItem?: SingleSelectData & { organization: Organization }
  serviceConfig: ServiceConfig
}

export enum ApiType {
  AUTHORIZATION = "AUTHORIZATION",
  DELEGATE = "DELEGATE",
  ORG_ROLE = "ORG_ROLE",
  ORG_MANDATES = "ORG_MANDATES",
  ORG_PERSON_MANDATES = "ORG_PERSON_MANDATES"
}

enum OrgModal {
  ADD,
  EDIT
}

const toOrganizationItem = (organization: Organization) => ({
  uniqueItemId: organization.names.fi,
  labelText: organization.names.fi,
  organization
})

const localizedContentWithUrl = (content: LocalizedContent) => ({ ...content, url: content.url || "" })

const ServiceSettings = () => {
  const authorizations = useAuthorizations()
  const { id } = useParams()
  const { data: service, isError: serviceError } = useService(id)
  const { data: allIssues, isError: allIssuesError } = useServiceAllIssues()
  const { data: allApis, isError: allApisError } = useServiceAllApis()
  const { data: users, isError: usersError } = useUsersForService(service?.uuid)
  const { data: organizations } = useOrganizations()
  const {
    status: updateStatus,
    mutate: updateService,
    isError: updateError,
    reset: updateReset,
    isPending: isUpdateLoading,
    isSuccess: isUpdateSuccess
  } = useUpdateService()

  const {
    mutate: deleteService,
    status: deleteStatus,
    isSuccess: deleteSuccess,
    isError: deleteError,
    reset: deleteReset
  } = useDeleteService()

  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false)
  const [orgModal, setOrgModal] = useState<OrgModal>()

  const { formatMessage, locale } = useIntl()
  const navigate = useNavigate()

  const organizationItems = useMemo(() => {
    return organizations ? organizations.map(toOrganizationItem) : []
  }, [organizations])

  useEffect(() => {
    if (deleteSuccess) {
      navigate("/", { state: { deleteStatus } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess])

  const initialValues: ServiceSettingsFormValues | undefined = useMemo(() => {
    if (service) {
      const localizedContent = service.localizedContent
        ? mapValues(service.localizedContent, localizedContentWithUrl)
        : initialLocalizedContent

      return {
        name: service.name,
        description: service.description,
        active: service.active,
        xroadSelected: !!service.serviceIdentifier,
        xinstance: service.xinstance || "",
        memberClass: service.memberClass || "",
        memberCode: service.memberCode || "",
        subsystemCode: service.subsystemCode || "",
        generateApiServiceIdentifier: false,
        generateApiSecret: false,
        generateApiOauthSecret: false,
        generateRestApiSecret: false,
        apiServiceIdentifier: service.apiServiceIdentifier,
        apiSecret: service.apiSecret,
        apiOauthSecret: service.apiOauthSecret,
        restApiSecret: service.restApiSecret,
        apiOauthRedirectUrls: service.apiOauthRedirectUrls.map(o => o.url || ""),
        localizedContent,
        serviceType: service.serviceType || undefined,
        organizationItem: service.organization ? toOrganizationItem(service.organization) : undefined,
        serviceConfig: {
          enabledApis: service.serviceConfig.enabledApis,
          hpaIssueUris: service.serviceConfig.hpaIssueUris,
          ypaIssueUris: service.serviceConfig.ypaIssueUris,
          yyaIssueUris: service.serviceConfig.yyaIssueUris,
          yhaIssueUris: service.serviceConfig.yhaIssueUris,
          hukoIssueUris: service.serviceConfig.hukoIssueUris,
          includeReasons: service.serviceConfig.includeReasons,
          includeOrganizationIdYpa: service.serviceConfig.includeOrganizationIdYpa,
          includeOrganizationIdHpa: service.serviceConfig.includeOrganizationIdHpa,
          includeForeignCompanies: service.serviceConfig.includeForeignCompanies,
          includeForeignPersons: service.serviceConfig.includeForeignPersons,
          includeInfoPrincipals: service.serviceConfig.includeInfoPrincipals,
          allowMultiSelection: service.serviceConfig.allowMultiSelection,
          allowEmptyVTJRules: service.serviceConfig.allowEmptyVTJRules,
          useNameSeparator: service.serviceConfig.useNameSeparator,
          nameSeparator: service.serviceConfig.nameSeparator
        }
      }
    } else {
      return {
        name: "",
        description: "",
        active: true,
        xroadSelected: false,
        xinstance: "",
        memberClass: "",
        memberCode: "",
        subsystemCode: "",
        generateApiServiceIdentifier: false,
        generateApiSecret: false,
        generateApiOauthSecret: false,
        generateRestApiSecret: false,
        apiServiceIdentifier: null,
        apiSecret: null,
        apiOauthSecret: null,
        restApiSecret: null,
        apiOauthRedirectUrls: [],
        localizedContent: initialLocalizedContent,
        serviceConfig: {
          enabledApis: [],
          hpaIssueUris: [],
          ypaIssueUris: [],
          yyaIssueUris: [],
          yhaIssueUris: [],
          hukoIssueUris: [],
          includeReasons: false,
          includeOrganizationIdYpa: false,
          includeOrganizationIdHpa: false,
          includeForeignCompanies: false,
          includeForeignPersons: false,
          includeInfoPrincipals: false,
          allowMultiSelection: false,
          allowEmptyVTJRules: false,
          useNameSeparator: false,
          nameSeparator: ", "
        }
      }
    }
  }, [service])

  const configRelatedApis: Record<string, string[]> = {
    "serviceConfig.includeReasons": [
      ApiType.AUTHORIZATION,
      ApiType.DELEGATE,
      ApiType.ORG_ROLE,
      ApiType.ORG_MANDATES,
      ApiType.ORG_PERSON_MANDATES
    ],
    "serviceConfig.allowMultiSelection": [ApiType.AUTHORIZATION, ApiType.ORG_ROLE],
    "serviceConfig.useNameSeparator": [ApiType.AUTHORIZATION, ApiType.DELEGATE],
    "serviceConfig.includeOrganizationIdHpa": [ApiType.AUTHORIZATION],
    "serviceConfig.includeForeignPersons": [ApiType.AUTHORIZATION],
    "serviceConfig.includeInfoPrincipals": [ApiType.AUTHORIZATION],
    "serviceConfig.allowEmptyVTJRules": [ApiType.AUTHORIZATION],
    "serviceConfig.includeOrganizationIdYpa": [ApiType.ORG_ROLE],
    "serviceConfig.includeForeignCompanies": [ApiType.ORG_ROLE]
  }

  const isConfigEnabled = (valueKey: string): boolean => {
    if (Object.keys(configRelatedApis).includes(valueKey)) {
      return selectedApis.some(api => configRelatedApis[valueKey].includes(api))
    }
    return true
  }

  const cleanUpServiceConfig = (serviceConfig: ServiceConfig) => {
    if (
      !serviceConfig.enabledApis.includes(ApiType.AUTHORIZATION) &&
      !serviceConfig.enabledApis.includes(ApiType.DELEGATE) &&
      !serviceConfig.enabledApis.includes(ApiType.ORG_ROLE) &&
      !serviceConfig.enabledApis.includes(ApiType.ORG_MANDATES) &&
      !serviceConfig.enabledApis.includes(ApiType.ORG_PERSON_MANDATES)
    ) {
      serviceConfig.includeReasons = false
    }
    if (!serviceConfig.enabledApis.includes(ApiType.AUTHORIZATION)) {
      serviceConfig.hpaIssueUris = []
      serviceConfig.hukoIssueUris = []
      serviceConfig.includeOrganizationIdHpa = false
      serviceConfig.includeForeignPersons = false
      serviceConfig.includeInfoPrincipals = false
      serviceConfig.allowEmptyVTJRules = false
      if (!serviceConfig.enabledApis.includes(ApiType.ORG_ROLE)) {
        serviceConfig.allowMultiSelection = false
      }
      if (!serviceConfig.enabledApis.includes(ApiType.DELEGATE)) {
        serviceConfig.useNameSeparator = false
        serviceConfig.nameSeparator = ", "
      }
    }
    if (!serviceConfig.enabledApis.includes(ApiType.ORG_ROLE)) {
      serviceConfig.ypaIssueUris = []
      serviceConfig.includeOrganizationIdYpa = false
      serviceConfig.includeForeignCompanies = false
    }
    if (!serviceConfig.enabledApis.includes(ApiType.ORG_MANDATES)) {
      serviceConfig.yyaIssueUris = []
    }
    if (!serviceConfig.enabledApis.includes(ApiType.ORG_PERSON_MANDATES)) {
      serviceConfig.yhaIssueUris = []
    }
    return serviceConfig
  }

  const onSubmit = ({
    name,
    description,
    active,
    xroadSelected,
    xinstance,
    memberClass,
    memberCode,
    subsystemCode,
    generateApiServiceIdentifier,
    generateApiSecret,
    generateApiOauthSecret,
    generateRestApiSecret,
    apiServiceIdentifier,
    apiSecret,
    apiOauthSecret,
    restApiSecret,
    apiOauthRedirectUrls,
    localizedContent,
    serviceType,
    organizationItem,
    serviceConfig
  }: ServiceSettingsFormValues) =>
    service &&
    updateService({
      ...service,
      name,
      description,
      active,
      xinstance: xroadSelected ? xinstance : null,
      memberClass: xroadSelected ? memberClass : null,
      memberCode: xroadSelected ? memberCode : null,
      subsystemCode: xroadSelected ? subsystemCode : null,
      generateApiServiceIdentifier,
      generateApiSecret,
      generateApiOauthSecret,
      generateRestApiSecret,
      apiServiceIdentifier,
      apiSecret,
      apiOauthSecret,
      restApiSecret,
      apiOauthRedirectUrls,
      localizedContent,
      serviceType: serviceType || null,
      organization: organizationItem?.organization,
      serviceConfig: { ...service.serviceConfig, ...cleanUpServiceConfig(serviceConfig) }
    })

  const { isConfirmOpen, openModal, closeModal, confirm } = useConfirmSave<ServiceSettingsFormValues>(
    isUpdateSuccess,
    onSubmit
  )

  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty }
  } = useForm({ defaultValues: initialValues, mode: "onBlur" })
  const { fields, append, remove } = useFieldArray({ control, name: "apiOauthRedirectUrls" as never })

  const isXroadSelected = watch("xroadSelected")
  const xroadValues = watch(["xinstance", "memberClass", "memberCode", "subsystemCode"])
  const serviceIdentifier = xroadValues.join("_")
  const validateServiceIdentifier = () => serviceIdentifier.length <= 100
  const isNameSeparatorSelected = watch("serviceConfig.useNameSeparator")
  const selectedApis = watch("serviceConfig.enabledApis")

  useEffect(() => {
    reset(initialValues)
  }, [initialValues, reset])

  const getStatus = (error: FieldError | undefined) => {
    return error ? "error" : "default"
  }

  if (!authorizations.includes(Auth.SERVICEMGMT) || !service || !allIssues || !allApis || !users) {
    return null
  }

  type BooleanCheckboxProps = {
    labelKey: string
    valueKey:
      | keyof Pick<
          ServiceSettingsFormValues,
          | "xroadSelected"
          | "generateApiServiceIdentifier"
          | "generateApiSecret"
          | "generateApiOauthSecret"
          | "generateRestApiSecret"
        >
      | "serviceConfig.includeReasons"
      | "serviceConfig.includeOrganizationIdYpa"
      | "serviceConfig.includeOrganizationIdHpa"
      | "serviceConfig.includeForeignCompanies"
      | "serviceConfig.includeForeignPersons"
      | "serviceConfig.includeInfoPrincipals"
      | "serviceConfig.allowMultiSelection"
      | "serviceConfig.allowEmptyVTJRules"
      | "serviceConfig.useNameSeparator"
    className?: string
  }

  const BooleanCheckbox = ({ labelKey, valueKey, className }: BooleanCheckboxProps) => (
    <Controller
      name={valueKey}
      control={control}
      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
        <Checkbox
          data-testid={`service-settings-${valueKey.split(".").pop()}-checkbox`}
          className={className}
          checked={value}
          onClick={({ checkboxState }) => {
            onChange(checkboxState)
            onBlur()
          }}
          status={getStatus(error)}
          disabled={!isConfigEnabled(valueKey)}
        >
          <FormattedMessage id={labelKey} />
        </Checkbox>
      )}
    />
  )

  type SecretProps = {
    labelKey: string
    valueKey: keyof Pick<
      ServiceSettingsFormValues,
      "apiServiceIdentifier" | "apiSecret" | "apiOauthSecret" | "restApiSecret"
    >
    generateKey: keyof Pick<
      ServiceSettingsFormValues,
      "generateApiServiceIdentifier" | "generateApiSecret" | "generateApiOauthSecret" | "generateRestApiSecret"
    >
  }

  const Secret = ({ labelKey, valueKey, generateKey }: SecretProps) => {
    const [copied, setCopied] = useState(false)

    const copyToClipboard = (text: string | null) =>
      text &&
      navigator.clipboard.writeText(text).then(() => {
        setCopied(true)
      })

    const value = watch(valueKey)

    return (
      <>
        <div>
          <Text variant="bold" smallScreen>
            <FormattedMessage id={labelKey} />
          </Text>
        </div>
        <div>
          <Text smallScreen>{value || "-"}</Text>
          <div className="d-inline-flex gap-m align-items-center ms-s">
            <Button
              aria-label=""
              data-testid={`service-settings-${valueKey}-copytoclipboard-button`}
              variant="secondaryNoBorder"
              icon={<IconCopy />}
              className="d-inline-flex justify-content-center align-items-center p-xs"
              disabled={!value}
              onClick={() => copyToClipboard(value)}
            />
            <TimedStatusText show={copied} onHide={() => setCopied(false)}>
              <FormattedMessage id="admin.common.copied.to.clipboard" />
            </TimedStatusText>
          </div>
        </div>
        <div className="d-flex gap-m align-items-center mb-m">
          <BooleanCheckbox labelKey="admin.generate.new" valueKey={generateKey} />
          <Controller
            name={valueKey}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Button
                data-testid={`service-settings-${valueKey}-remove-button`}
                variant="secondaryNoBorder"
                icon={<IconRemove />}
                disabled={!value}
                onClick={() => onChange(null)}
              >
                <FormattedMessage id="admin.button.poista" />
              </Button>
            )}
          />
        </div>
      </>
    )
  }

  type IssueSelectProps = {
    allIssues: Issue[]
    labelKey: string
    valueKey: keyof Pick<
      ServiceSettingsFormValues["serviceConfig"],
      "hpaIssueUris" | "ypaIssueUris" | "yyaIssueUris" | "yhaIssueUris" | "hukoIssueUris"
    >
    showUri?: boolean
    showHukoRoles?: boolean
    disabled?: boolean
  }

  const IssueSelect = ({
    allIssues,
    labelKey,
    valueKey,
    showUri,
    showHukoRoles,
    disabled = false
  }: IssueSelectProps) => {
    const [filter, setFilter] = useState("")

    const getIssueLabel = (
      { uri, active, labels, hukoRoles }: Issue,
      { showUri = false, showHukoRoles = false } = {}
    ) => {
      const label = labels[locale] ? labels[locale] : uri
      const activeInfo = active ? "" : " ***"
      const uriInfo = showUri && labels[locale] ? ` (${uri})` : ""
      const hukoInfo = showHukoRoles && hukoRoles && hukoRoles.length > 0 ? ` (${hukoRoles.join(", ")})` : ""
      return label + hukoInfo + uriInfo + activeInfo
    }

    const minFilterLength = 3
    const isFilterActive = filter.length >= minFilterLength

    return (
      <Controller
        name={`serviceConfig.${valueKey}`}
        control={control}
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <MultiSelect
            data-testid={`service-settings-${valueKey}-multiselect`}
            className="w-100 mb-l"
            onChange={setFilter}
            items={allIssues
              .map(issue => ({ uniqueItemId: issue.uri, labelText: getIssueLabel(issue, { showUri, showHukoRoles }) }))
              // actual filter happens in component matching only labelText and this cannot be changed.
              // this just disables rendering all without having filter term for performance reasons.
              .filter(() => isFilterActive)
              .sort((a, b) => a.labelText.localeCompare(b.labelText, locale))}
            selectedItems={value.map(uri => {
              const issue = allIssues.find(issue => issue.uri === uri)
              return {
                uniqueItemId: uri,
                labelText: issue ? getIssueLabel(issue, { showHukoRoles }) : uri
              }
            })}
            onItemSelect={uniqueItemId => {
              if (uniqueItemId !== null) {
                const uris = !value.includes(uniqueItemId)
                  ? value.concat(uniqueItemId)
                  : value.filter(uri => uri !== uniqueItemId)
                onChange(uris)
              } else {
                onChange(value)
              }
            }}
            onBlur={() => {
              setFilter("")
              onBlur()
            }}
            labelText={formatMessage({ id: labelKey })}
            visualPlaceholder={formatMessage({ id: "admin.common.select" })}
            noItemsText={
              isFilterActive
                ? formatMessage({ id: "admin.common.input.filter.no.results" })
                : formatMessage({ id: "admin.common.input.filter.min.length" }, { length: minFilterLength })
            }
            ariaSelectedAmountText=""
            ariaOptionsAvailableText=""
            ariaOptionChipRemovedText=""
            chipListVisible
            status={getStatus(error)}
            disabled={disabled}
          />
        )}
      />
    )
  }

  const renderNotifications = () => {
    return (
      <>
        {serviceError && (
          <ErrorNotification>
            <FormattedMessage id="admin.error.palvelu.epaonnistui" />
          </ErrorNotification>
        )}
        {allIssuesError && (
          <ErrorNotification>
            <FormattedMessage id="admin.error.issue.fetch" />
          </ErrorNotification>
        )}
        {allApisError && (
          <ErrorNotification>
            <FormattedMessage id="admin.error.rajapintahaku.epaonnistui" />
          </ErrorNotification>
        )}
        {usersError && (
          <ErrorNotification>
            <FormattedMessage id="admin.error.kayttajahaku.epaonnistui" />
          </ErrorNotification>
        )}
        {updateError && (
          <ErrorNotification onClose={updateReset}>
            <FormattedMessage id="admin.error.message.default" />
          </ErrorNotification>
        )}
        {deleteError && (
          <ErrorNotification onClose={deleteReset}>
            <FormattedMessage id="admin.error.poisto.epaonnistui" />
          </ErrorNotification>
        )}
        <TimedToast status={updateStatus}>
          <FormattedMessage id="admin.info.tallennettu" />
        </TimedToast>
      </>
    )
  }

  const renderBaseDetails = () => (
    <>
      <Controller
        name="name"
        control={control}
        rules={{
          required: "admin.validation.required",
          pattern: { value: /\S+/, message: "admin.validation.required" },
          minLength: { value: 2, message: "admin.validation.minLength" }
        }}
        render={({ field, fieldState: { error } }) => (
          <TextInput
            {...field}
            data-testid="service-settings-name-input"
            fullWidth
            className="mb-l"
            maxLength={50}
            labelText={formatMessage({ id: "admin.field.nimi" })}
            status={getStatus(error)}
            statusText={
              error && formatMessage({ id: error.message }, error.type === "minLength" ? { value: 2 } : undefined)
            }
          />
        )}
      />

      <Textarea
        {...register("description", {
          required: "admin.validation.required",
          pattern: { value: /\S+/, message: "admin.validation.required" }
        })}
        data-testid="service-settings-description-input"
        fullWidth
        className="mb-l"
        maxLength={1000}
        rows={4}
        labelText={formatMessage({ id: "admin.field.kuvaus" })}
        status={getStatus(errors["description"])}
        statusText={errors.description && formatMessage({ id: errors.description.message })}
      />

      <Controller
        control={control}
        name="serviceType"
        rules={{ required: "admin.validation.required" }}
        render={({ field, fieldState: { error } }) => (
          <div className="mb-l">
            <RadioButtonGroup
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              labelText={formatMessage({ id: "admin.field.service_type.label" })}
              className="mb-xxs"
            >
              <RadioButton
                data-testid="service-type-option-public"
                value={ServiceType.PUBLIC}
                ref={field.ref}
                disabled={field.disabled}
              >
                <FormattedMessage id="admin.field.service_type.option.public" />
              </RadioButton>
              <RadioButton
                data-testid="service-type-option-private"
                value={ServiceType.PRIVATE}
                disabled={field.disabled}
              >
                <FormattedMessage id="admin.field.service_type.option.private" />
              </RadioButton>
            </RadioButtonGroup>
            {error && (
              <StatusText status="error">
                <FormattedMessage id="admin.validation.required" />
              </StatusText>
            )}
          </div>
        )}
      />

      <Label className={styles.serviceSettingsActiveTitle}>
        <FormattedMessage id="admin.checkbox.kaytossa.title" />
      </Label>
      <Controller
        name="active"
        control={control}
        render={({ field: { value, onChange, onBlur } }) => (
          <ToggleInput
            data-testid="service-settings-active-input"
            className="mb-l"
            checked={value}
            onClick={checked => {
              onChange(checked)
              onBlur()
            }}
            disabled={!isConfigEnabled("active")}
          >
            <FormattedMessage id={`admin.checkbox.kaytossa.${value ? "checked" : "unchecked"}`} />
          </ToggleInput>
        )}
      />
    </>
  )

  const renderPublicInfo = () => (
    <>
      <Heading variant="h2" className="my-m">
        <FormattedMessage id="admin.header.service.publicInfo" />
      </Heading>
      <Controller
        name="organizationItem"
        control={control}
        rules={{ required: "admin.validation.required" }}
        render={({ field, fieldState: { error } }) => (
          <div className="mb-l">
            <div className="mb-xxs row align-items-end">
              <div className="col">
                <SingleSelect
                  data-testid="service-org-select"
                  className="w-100"
                  onBlur={field.onBlur}
                  status={getStatus(error)}
                  selectedItem={field.value}
                  onItemSelectionChange={field.onChange}
                  items={organizationItems}
                  labelText={formatMessage({ id: "admin.organizations.organization" })}
                  clearButtonLabel=""
                  noItemsText={formatMessage({ id: "admin.common.input.filter.no.results" })}
                  ariaOptionsAvailableText=""
                />
              </div>
              <div className="col-auto">
                {field.value ? (
                  <Button
                    aria-label={formatMessage({ id: "admin.button.muokkaa" })}
                    data-testid="service-org-edit-button"
                    icon={<IconEdit />}
                    variant="secondaryNoBorder"
                    onClick={() => setOrgModal(OrgModal.EDIT)}
                  />
                ) : (
                  <Button data-testid="service-org-add-button" onClick={() => setOrgModal(OrgModal.ADD)}>
                    <FormattedMessage id="admin.organizations.add_organization" />
                  </Button>
                )}
              </div>
            </div>
            {error && (
              <StatusText status="error">
                <FormattedMessage id={error.message} />
              </StatusText>
            )}
          </div>
        )}
      />
      {supportedLanguages.map(lang => (
        <div key={lang} className="row">
          <div className="col-12 col-md">
            <Controller
              name={`localizedContent.${lang}.displayName`}
              control={control}
              rules={{
                required: "admin.validation.required",
                pattern: { value: /\S+/, message: "admin.validation.required" }
              }}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  {...field}
                  data-testid={`service-settings-${field.name}-input`}
                  fullWidth
                  className="mb-l"
                  maxLength={100}
                  labelText={formatMessage({ id: `admin.field.displayName.${lang}` })}
                  status={getStatus(error)}
                  statusText={error && formatMessage({ id: error.message })}
                />
              )}
            />
          </div>
          <div className="col-12 col-md">
            <Controller
              name={`localizedContent.${lang}.url`}
              control={control}
              rules={{ pattern: { value: /^https?:\/\//, message: "admin.field.url.error.format" } }}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  {...field}
                  data-testid={`service-settings-${field.name}-input`}
                  fullWidth
                  className="mb-l"
                  maxLength={120}
                  labelText={formatMessage({ id: `admin.field.url.${lang}` })}
                  status={getStatus(error)}
                  statusText={error && formatMessage({ id: error.message })}
                />
              )}
            />
          </div>
        </div>
      ))}
    </>
  )

  const renderAllowedRedirectUrls = () => {
    return (
      <>
        {fields.map(({ id }, index) => (
          <div key={id} className="d-flex gap-m mb-s">
            <Controller
              name={`apiOauthRedirectUrls.${index}`}
              control={control}
              rules={{
                required: "admin.validation.required",
                pattern: {
                  value: /^(https:\/\/[^\s#]+$)|(https?:\/\/(localhost|127\.0\.0\.1)[^\s#]*$)/,
                  message: "admin.validation.pattern"
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  {...field}
                  data-testid={`service-settings-${field.name}-input`}
                  fullWidth
                  maxLength={1000}
                  labelText=""
                  labelMode="hidden"
                  status={getStatus(error)}
                  statusText={error && formatMessage({ id: error.message })}
                />
              )}
            />
            <div>
              <Button
                data-testid={`service-settings-apiOauthRedirectUrls.${index}-remove-button`}
                variant="secondaryNoBorder"
                icon={<IconRemove />}
                className="text-nowrap"
                onClick={() => remove(index)}
              >
                <FormattedMessage id="admin.button.poista" />
              </Button>
            </div>
          </div>
        ))}
        <div>
          <Button
            data-testid={`service-settings-apiOauthRedirectUrls-add-button`}
            variant="secondary"
            onClick={() => append("")}
          >
            <FormattedMessage id="admin.button.lisaa" />
          </Button>
        </div>
      </>
    )
  }

  const getXRoadFieldStatusText = (error?: FieldError) => {
    if (error && error.type !== "validate") {
      return formatMessage({ id: error.message })
    }
  }

  const renderIdDetails = () => (
    <>
      <Heading variant="h2" className="my-m">
        <FormattedMessage id="admin.header.service.ids" />
      </Heading>
      <div>
        <Text variant="bold" smallScreen>
          UUID
        </Text>
      </div>
      <div className="mb-m">
        <Text smallScreen>{service?.uuid}</Text>
      </div>
      <div className="mb-m">
        <BooleanCheckbox labelKey="admin.create.service.xroad" valueKey="xroadSelected" />
      </div>
      {isXroadSelected && (
        <>
          <div>
            <Text variant="bold" smallScreen>
              <FormattedMessage id="admin.field.serviceIdentifier" />
            </Text>
          </div>
          <div className="mb-m">
            <Text smallScreen>{serviceIdentifier}</Text>
            <StatusText status="error">
              {!validateServiceIdentifier() && <FormattedMessage id="admin.error.tunniste.liika.pitka" />}
            </StatusText>
          </div>
          <div className="my-l">
            <div className="d-flex flex-wrap flex-md-nowrap gap-l gap-md-m mb-l">
              <Controller
                name="xinstance"
                control={control}
                shouldUnregister
                rules={{
                  required: {
                    value: isXroadSelected,
                    message: "admin.validation.required"
                  },
                  pattern: {
                    value: /^[0-9A-Za-z.-]+$/,
                    message: "admin.validation.pattern"
                  },
                  validate: validateServiceIdentifier
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    {...field}
                    data-testid="service-settings-xinstance-input"
                    fullWidth
                    className="w-50"
                    maxLength={87}
                    labelText={formatMessage({ id: "admin.field.xinstance" })}
                    visualPlaceholder={formatMessage({ id: "admin.placeholder.xinstance" })}
                    status={getStatus(error)}
                    statusText={getXRoadFieldStatusText(error)}
                  />
                )}
              />
              <Controller
                name="memberClass"
                control={control}
                shouldUnregister
                rules={{
                  required: {
                    value: isXroadSelected,
                    message: "admin.validation.required"
                  },
                  pattern: {
                    value: /^[0-9A-Za-z.-]+$/,
                    message: "admin.validation.pattern"
                  },
                  validate: validateServiceIdentifier
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    {...field}
                    data-testid="service-settings-memberClass-input"
                    fullWidth
                    className="w-50"
                    maxLength={87}
                    labelText={formatMessage({ id: "admin.field.memberClass" })}
                    visualPlaceholder={formatMessage({ id: "admin.placeholder.memberClass" })}
                    status={getStatus(error)}
                    statusText={getXRoadFieldStatusText(error)}
                  />
                )}
              />
              <Controller
                name="memberCode"
                control={control}
                shouldUnregister
                rules={{
                  required: {
                    value: isXroadSelected,
                    message: "admin.validation.required"
                  },
                  pattern: {
                    value: /^[0-9]{7}-[0-9]{1}$/,
                    message: "admin.validation.pattern"
                  },
                  validate: validateServiceIdentifier
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    {...field}
                    data-testid="service-settings-memberCode-input"
                    fullWidth
                    maxLength={9}
                    labelText={formatMessage({ id: "admin.field.memberCode" })}
                    visualPlaceholder={formatMessage({ id: "admin.placeholder.memberCode" })}
                    status={getStatus(error)}
                    statusText={getXRoadFieldStatusText(error)}
                  />
                )}
              />
            </div>
            <Controller
              name="subsystemCode"
              control={control}
              shouldUnregister
              rules={{
                required: {
                  value: isXroadSelected,
                  message: "admin.validation.required"
                },
                pattern: {
                  value: /^[0-9A-Za-z._-]+$/,
                  message: "admin.validation.pattern"
                },
                validate: validateServiceIdentifier
              }}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  {...field}
                  data-testid="service-settings-subsystemCode-input"
                  fullWidth
                  maxLength={87}
                  labelText={formatMessage({ id: "admin.field.subsystemCode" })}
                  visualPlaceholder={formatMessage({ id: "admin.placeholder.subsystemCode" })}
                  status={getStatus(error)}
                  statusText={getXRoadFieldStatusText(error)}
                />
              )}
            />
          </div>
        </>
      )}
      <Secret
        labelKey="admin.web.api.client.id"
        valueKey="apiServiceIdentifier"
        generateKey="generateApiServiceIdentifier"
      />
      <Secret
        labelKey="admin.web.api.rest.client.secret"
        valueKey="restApiSecret"
        generateKey="generateRestApiSecret"
      />
      <Secret labelKey="admin.web.api.client.secret" valueKey="apiSecret" generateKey="generateApiSecret" />
      <Secret labelKey="admin.web.api.oauth.secret" valueKey="apiOauthSecret" generateKey="generateApiOauthSecret" />
      <Heading variant="h3" className="my-m">
        <FormattedMessage id="admin.web.api.oauth.redirect.urls" />
      </Heading>
      {renderAllowedRedirectUrls()}
    </>
  )

  const renderAPIDetails = () => (
    <>
      <Heading variant="h2" className="my-m">
        <FormattedMessage id="admin.apis" />
      </Heading>
      <Controller
        name="serviceConfig.enabledApis"
        control={control}
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <MultiSelect
            data-testid="service-settings-enabledApis-multiselect"
            className="w-100 mb-l"
            items={allApis
              .map(id => ({ uniqueItemId: id, labelText: formatMessage({ id }) }))
              .sort((a, b) => a.labelText.localeCompare(b.labelText, locale))}
            selectedItems={value.map(id => ({ uniqueItemId: id, labelText: formatMessage({ id }) }))}
            onItemSelect={uniqueItemId => {
              if (uniqueItemId !== null) {
                const ids = !value.includes(uniqueItemId)
                  ? value.concat(uniqueItemId)
                  : value.filter(id => id !== uniqueItemId)
                onChange(ids)
              } else {
                onChange(value)
              }
            }}
            onBlur={onBlur}
            labelText={formatMessage({ id: "admin.apis" })}
            visualPlaceholder={formatMessage({ id: "admin.common.select" })}
            noItemsText={formatMessage({ id: "admin.common.input.filter.no.results" })}
            ariaSelectedAmountText=""
            ariaOptionsAvailableText=""
            ariaOptionChipRemovedText=""
            chipListVisible
            status={getStatus(error)}
          />
        )}
      />
      <Heading variant="h3" className="my-m">
        <FormattedMessage id="admin.header.service.apis.settings" />
      </Heading>
      <IssueSelect
        allIssues={allIssues}
        labelKey="admin.hpa.mandates"
        valueKey="hpaIssueUris"
        showUri
        disabled={!selectedApis.includes(ApiType.AUTHORIZATION)}
      />
      <IssueSelect
        allIssues={allIssues.filter(({ hukoRoles }) => hukoRoles && hukoRoles.length > 0)}
        labelKey="admin.huko.mandates"
        valueKey="hukoIssueUris"
        showHukoRoles
        disabled={!selectedApis.includes(ApiType.AUTHORIZATION)}
      />
      <IssueSelect
        allIssues={allIssues}
        labelKey="admin.ypa.mandates"
        valueKey="ypaIssueUris"
        showUri
        disabled={!selectedApis.includes(ApiType.ORG_ROLE)}
      />
      <IssueSelect
        allIssues={allIssues}
        labelKey="admin.yya.mandates"
        valueKey="yyaIssueUris"
        showUri
        disabled={!selectedApis.includes(ApiType.ORG_MANDATES)}
      />
      <IssueSelect
        allIssues={allIssues}
        labelKey="admin.yha.mandates"
        valueKey="yhaIssueUris"
        showUri
        disabled={!selectedApis.includes(ApiType.ORG_PERSON_MANDATES)}
      />
      <BooleanCheckbox labelKey="admin.checkbox.hylkaysperusteet" valueKey="serviceConfig.includeReasons" />
      <BooleanCheckbox labelKey="admin.checkbox.monivalinta" valueKey="serviceConfig.allowMultiSelection" />
      <BooleanCheckbox labelKey="admin.checkbox.use.name.separator" valueKey="serviceConfig.useNameSeparator" />
      {isNameSeparatorSelected && (
        <Controller
          name="serviceConfig.nameSeparator"
          control={control}
          shouldUnregister
          rules={{
            required: { value: isNameSeparatorSelected, message: "admin.validation.required" },
            pattern: { value: /^[ ,\-.:;_]{1,2}$/, message: "admin.text.name.separator.error" }
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <div style={{ width: 100 }}>
                <TextInput
                  {...field}
                  data-testid="service-settings-nameSeparator-input"
                  fullWidth
                  maxLength={2}
                  labelText={formatMessage({ id: "admin.text.name.separator" })}
                  status={getStatus(error)}
                  disabled={!isConfigEnabled("serviceConfig.useNameSeparator")}
                />
              </div>
              <StatusText status={getStatus(error)} className="mt-xxs">
                {error?.message ? formatMessage({ id: error.message }) : ""}
              </StatusText>
            </>
          )}
        />
      )}
      <BooleanCheckbox
        labelKey="admin.checkbox.organisaatiotunniste.hpa"
        valueKey="serviceConfig.includeOrganizationIdHpa"
      />
      <BooleanCheckbox
        labelKey="admin.checkbox.ulkomaalaiset.henkilot"
        valueKey="serviceConfig.includeForeignPersons"
      />
      <BooleanCheckbox
        labelKey="admin.checkbox.include.info.principals"
        valueKey="serviceConfig.includeInfoPrincipals"
      />
      <BooleanCheckbox labelKey="admin.checkbox.tyhjat.vtj.saannot" valueKey="serviceConfig.allowEmptyVTJRules" />
      <BooleanCheckbox
        labelKey="admin.checkbox.organisaatiotunniste.ypa"
        valueKey="serviceConfig.includeOrganizationIdYpa"
      />
      <BooleanCheckbox
        labelKey="admin.checkbox.ulkomaalaiset.yritykset"
        valueKey="serviceConfig.includeForeignCompanies"
      />
    </>
  )

  const renderUserDetails = () => (
    <>
      <Heading variant="h2" className="my-m">
        <FormattedMessage id="admin.field.kayttajat" />
      </Heading>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="admin.field.kayttaja" />
            </th>
            <th>
              <FormattedMessage id="admin.field.kayttaja.oikeudet" />
            </th>
            <th>
              <FormattedMessage id="admin.button.muokkaa" />
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.uuid}>
              <td>{user.name}</td>
              <td>
                {user.userAuthorizations &&
                  user.userAuthorizations
                    .filter(({ roleName }) => roleName !== Auth.NOTIFICATIONMGMT)
                    .map(({ roleName }) => formatMessage({ id: roleName }))
                    .sort((a, b) => a.localeCompare(b, locale))
                    .join(", ")}
              </td>
              <td>
                <Button
                  aria-label={formatMessage({ id: "admin.button.muokkaa" })}
                  data-testid={`service-settings-jenkins-edit-button`}
                  onClick={() => navigate(`/users/${user.uuid}`)}
                  variant="secondaryNoBorder"
                  icon={<IconEdit />}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )

  const renderActions = () => (
    <div className="d-flex gap-m align-items-center mt-l">
      <Button data-testid="service-settings-save-button" type="submit">
        <FormattedMessage id="admin.button.tallenna" />
      </Button>
      <SaveModal visible={isConfirmOpen} loading={isUpdateLoading} onConfirm={confirm} onCancel={closeModal} />
      <Button
        data-testid="service-settings-remove-button"
        variant="secondaryNoBorder"
        onClick={() => setOpenRemoveModal(true)}
      >
        <FormattedMessage id="admin.button.poista.palvelu" />
      </Button>
      <RemoveModal
        name={service.name}
        visible={openRemoveModal}
        header="admin.modal.poisto.header"
        confirmationQuestion="admin.modal.poisto.otsikko"
        onRemove={() => {
          setOpenRemoveModal(false)
          deleteService(`${service.id}`)
        }}
        onCancel={() => setOpenRemoveModal(false)}
      />
      <Button
        data-testid="service-settings-back-button"
        variant="secondaryNoBorder"
        onClick={() => navigate("/services")}
      >
        <FormattedMessage id="admin.button.takaisin" />
      </Button>
    </div>
  )

  const organizationItem = watch("organizationItem")

  return (
    <>
      <NavigationBlocker shouldBlock={isDirty} />
      <Heading variant="h1" className="mb-m">
        <FormattedMessage id="admin.header.service.settings" />
      </Heading>
      <Paragraph className="my-m">
        <FormattedMessage id="admin.description.service.settings" />
      </Paragraph>
      {renderNotifications()}
      <form onSubmit={handleSubmit(openModal)}>
        {renderBaseDetails()}
        <Divider className="my-xxl" />
        {renderPublicInfo()}
        <Divider className="my-xxl" />
        {renderIdDetails()}
        <Divider className="my-xxl" />
        {renderAPIDetails()}
        <Divider className="my-xxl" />
        {renderUserDetails()}
        <Divider className="my-xxl" />
        {renderActions()}
      </form>
      {organizationItem && (
        <OrganizationEditModal
          visible={orgModal === OrgModal.EDIT}
          organization={organizationItem.organization}
          onSuccess={organization => {
            setValue("organizationItem", toOrganizationItem(organization))
            setOrgModal(undefined)
          }}
          onClose={() => setOrgModal(undefined)}
        />
      )}
      <OrganizationAddModal
        visible={orgModal === OrgModal.ADD}
        onSuccess={organization => {
          setValue("organizationItem", toOrganizationItem(organization), { shouldValidate: true })
          setOrgModal(undefined)
        }}
        onClose={() => setOrgModal(undefined)}
      />
    </>
  )
}

export { ServiceSettings }
