import { Button, Modal, ModalContent, ModalTitle, ModalFooter, Paragraph, Text } from "suomifi-ui-components"
import { FormattedMessage } from "react-intl"

type Props = {
  name: string
  visible: boolean
  header: string
  confirmationQuestion: string
  onRemove: () => void
  onCancel: () => void
}

const RemoveModal = ({ name, visible, header, confirmationQuestion, onRemove, onCancel }: Props) => {
  return (
    <Modal appElementId="root" visible={visible} variant="default" onEscKeyDown={() => onCancel()}>
      <ModalContent>
        <ModalTitle>
          <FormattedMessage id={header} />
        </ModalTitle>
        <Paragraph>
          <FormattedMessage id={confirmationQuestion}>{text => <Text>{`${text} "roles-auths-admin-ui"?`}</Text>}</FormattedMessage>
        </Paragraph>
        <Paragraph>
          <Text>
            <FormattedMessage id="admin.modal.poisto.alaotsikko" />
          </Text>
        </Paragraph>
      </ModalContent>
      <ModalFooter>
        <Button
          data-testid="remove-modal-confirm-button"
          onClick={() => {
            onRemove()
          }}
        >
          <FormattedMessage id="admin.button.kylla" />
        </Button>
        <Button data-testid="remove-modal-cancel-button" variant="secondary" onClick={() => onCancel()}>
          <FormattedMessage id="admin.button.peruuta" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { RemoveModal }
