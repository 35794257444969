import { useMutation, useQueryClient } from "@tanstack/react-query"
import { deleteOrganization } from "../api"
import { Organization } from "../types"

const useDeleteOrganization = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteOrganization,
    onSuccess: (_, id) => {
      queryClient.setQueryData<Organization[]>(["organizations"], old =>
        old ? old.filter(organization => organization.id !== id) : undefined
      )
    }
  })
}

export default useDeleteOrganization
