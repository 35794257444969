import { ReactNode, useEffect, useState } from "react"
import { Toast } from "suomifi-ui-components"
import styles from "./TimedToast.module.scss"

const TimedToast = ({ status, children }: { status: string; children: ReactNode }) => {
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    if (status === "success") {
      setVisible(true)
      setTimeout(() => setVisible(false), 10000)
    }
  }, [status])

  if (!visible) {
    return null
  }

  return <Toast className={styles.timedToast}>{children}</Toast>
}

export { TimedToast }
