import { useEffect, useMemo } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useSessionStorage } from "react-use"
import { Button, MultiSelect, TextInput, ToggleButton } from "suomifi-ui-components"
import { Divider } from "../../components/Divider"
import { useAuthorizations, useRuleSets, useServiceAllApis } from "../../hooks"
import { Auth, Rule, RuleConfig, RuleSet, Service } from "../../types"
import { filterByTerm, flattenServiceForDeepSearch } from "../../utils/filtering"
import { ServiceRulesFilter } from "./ServiceRulesFilter"
import { ServiceSettingsFilter } from "./ServiceSettingsFilter"
import { ServiceRulesFilterValue, ServicesFilterValue } from "./servicesFilter.types"

type Props = {
  services: Service[]
  setFilteredServices: (services: Service[]) => void
  setFiltersActive: (active: boolean) => void
}

const ruleConfigOptions = [
  { ruleId: "015.002.1.1", configKey: "roles", valueGroup: "TJ", value: "TJ", valueLabelKey: "role.TJ" },
  { ruleId: "015.002.1.1", configKey: "roles", valueGroup: "TJ", value: "TJS", valueLabelKey: "role.TJS" },
  { ruleId: "015.002.1.1", configKey: "roles", valueGroup: "TIL", value: "T", valueLabelKey: "role.T" },
  { ruleId: "015.002.1.1", configKey: "roles", valueGroup: "TIL", value: "PTI", valueLabelKey: "role.PTI" },
  { ruleId: "015.002.1.1", configKey: "roles", valueGroup: "TIL", value: "LT", valueLabelKey: "role.LT" },
  { ruleId: "015.002.1.1", configKey: "roles", valueGroup: "ELI", value: "ELI", valueLabelKey: "role.ELI" },
  { ruleId: "015.002.1.1", configKey: "roles", valueGroup: "S", value: "S", valueLabelKey: "role.S" },
  { ruleId: "015.002.1.1", configKey: "roles", valueGroup: "IS", value: "IS", valueLabelKey: "role.IS" },
  { ruleId: "015.002.1.1", configKey: "roles", valueGroup: "IS", value: "PIS", valueLabelKey: "role.PIS" },
  { ruleId: "015.002.1.1", configKey: "roles", valueGroup: "YHM", value: "YHM", valueLabelKey: "role.YHM" },
  { ruleId: "016.002.1.2", configKey: "right.level", value: "OI", valueLabelKey: "OI" },
  { ruleId: "016.002.1.2", configKey: "right.level", value: "P", valueLabelKey: "P" },
  { ruleId: "018.002.1.4", configKey: "phase", valueIsList: true, value: "KON", valueLabelKey: "KON" },
  { ruleId: "018.002.1.4", configKey: "phase", valueIsList: true, value: "SAN", valueLabelKey: "SAN" },
  { ruleId: "018.002.1.4", configKey: "phase", valueIsList: true, value: "SEL", valueLabelKey: "SEL" },
  {
    ruleId: "021.001.2.2.3",
    configKey: "guardian_role_enabled",
    value: "true",
    valueLabelKey: "guardian_role_enabled"
  },
  { ruleId: "022.002.1.5", configKey: "llcroles", valueGroup: "PJ", value: "PJ", valueLabelKey: "role.PJ" },
  { ruleId: "022.002.1.5", configKey: "llcroles", valueGroup: "J", value: "J", valueLabelKey: "role.J" },
  { ruleId: "025.005.1.1", configKey: "status", valueIsList: true, value: "KON", valueLabelKey: "KON" },
  { ruleId: "025.005.1.1", configKey: "status", valueIsList: true, value: "SAN", valueLabelKey: "SAN" },
  { ruleId: "025.005.1.1", configKey: "status", valueIsList: true, value: "SEL", valueLabelKey: "SEL" },
  { ruleId: "029.007.1.1", configKey: "association_roles", valueGroup: "PJ", value: "PJ", valueLabelKey: "role.PJ" },
  { ruleId: "029.007.1.1", configKey: "association_roles", valueGroup: "J", value: "J", valueLabelKey: "role.J" }
]

const getMatchingRulesWithConfig = (ruleSets: RuleSet[] = [], { ruleId }: ServiceRulesFilterValue) =>
  ruleSets
    .filter(({ rules }) => !!rules[ruleId] && !rules[ruleId].fixed)
    .map(({ rules, ruleConfig }) => ({ rule: rules[ruleId], config: ruleConfig[ruleId] }))

const parseRuleConfig = (config: RuleConfig, configKey: string) =>
  config[configKey]
    ?.split(";")
    .map(group => {
      const [key, roles] = group.split("=")
      return { [key]: roles?.split(",") }
    })
    .reduce((prev, curr) => ({ ...prev, ...curr })) || {}

const isRuleSelected = (
  rule: Rule,
  config: RuleConfig = {},
  { configKey, valueGroup, valueIsList, value }: ServiceRulesFilterValue
) => {
  if (configKey && valueGroup && value) {
    return parseRuleConfig(config, configKey)[valueGroup]?.includes(value)
  } else if (configKey && valueIsList && value) {
    return config[configKey]?.split(",").includes(value)
  } else if (configKey && value) {
    return config[configKey] === value
  } else {
    return rule.selected
  }
}

export const ServicesFilter = ({ services, setFilteredServices, setFiltersActive }: Props) => {
  const authorizations = useAuthorizations()
  const isAdmin = authorizations.includes(Auth.SERVICEMGMT)
  const { data: allApis = [] } = useServiceAllApis({ enabled: isAdmin })
  const ruleSetsQuery = useRuleSets({ enabled: isAdmin })
  const ruleSets = useMemo(() => ruleSetsQuery.data ?? {}, [ruleSetsQuery.data])
  const intl = useIntl()
  const { formatMessage, locale } = intl

  const initialValues: ServicesFilterValue = {
    term: "",
    enabledRules: [],
    disabledRules: [],
    enabledSettings: [],
    disabledSettings: [],
    enabledApis: []
  }
  const [showMore, setShowMore] = useSessionStorage("showMore", false)
  const [storedFilters, setStoredFilters] = useSessionStorage("filters", initialValues)
  const isFiltersActive = Object.values(storedFilters).some(value => value.length > 0)
  const { control, reset } = useForm({ defaultValues: storedFilters })
  const filters = useWatch({ control }) as ServicesFilterValue
  const { term, enabledRules, disabledRules, enabledSettings, disabledSettings, enabledApis } = filters

  useEffect(() => {
    setStoredFilters(filters)
  }, [filters, setStoredFilters])

  useEffect(() => {
    if (services.length < 1) {
      return
    }

    setFilteredServices(
      services
        .filter(service => filterByTerm(term, flattenServiceForDeepSearch(service)))
        .filter(service => enabledApis.every(api => service.serviceConfig.enabledApis.includes(api)))
        .filter(service => enabledSettings.every(setting => service.serviceConfig[setting] === true))
        .filter(service => disabledSettings.every(setting => service.serviceConfig[setting] !== true))
        .filter(service =>
          enabledRules.every(filterRule => {
            const serviceRules = getMatchingRulesWithConfig(ruleSets[service.uuid], filterRule)
            return serviceRules.length > 0
              ? serviceRules.some(({ rule, config }) => isRuleSelected(rule, config, filterRule))
              : false
          })
        )
        .filter(service =>
          disabledRules.every(filterRule => {
            const serviceRules = getMatchingRulesWithConfig(ruleSets[service.uuid], filterRule)
            return serviceRules.length > 0
              ? serviceRules.every(({ rule, config }) => !isRuleSelected(rule, config, filterRule))
              : false
          })
        )
    )
  }, [
    services,
    ruleSets,
    setFilteredServices,
    term,
    enabledRules,
    disabledRules,
    enabledSettings,
    disabledSettings,
    enabledApis
  ])

  useEffect(() => {
    setFiltersActive(isFiltersActive)
  }, [setFiltersActive, isFiltersActive])

  // all rulesets are populated for all services so just get possible options from first one
  // if data is not found for any services then advanced filters are not visible
  const allRules: ServiceRulesFilterValue[] = useMemo(
    () =>
      Object.keys(ruleSets).length > 0
        ? Object.values(ruleSets)[0]
            .map(({ rules }) => Object.values(rules))
            .reduce((prev, curr) => [...prev, ...curr])
            .filter(({ fixed }) => !fixed)
            .filter(({ ruleId }, index, rules) => rules.findIndex(rule => rule.ruleId === ruleId) === index)
            .map(({ ruleId }) => ({ id: ruleId, ruleId }))
            .concat(ruleConfigOptions.map(config => ({ ...config, id: Object.entries(config).join(";") })))
        : [],
    [ruleSets]
  )

  return (
    <div className="my-m p-m bg-depth-light3">
      <Controller
        name="term"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            data-testid="services-filter-input"
            labelText={formatMessage({ id: "admin.filter.services" })}
            visualPlaceholder={formatMessage({ id: "admin.info.kirjoita" })}
            fullWidth
          />
        )}
      />
      {isAdmin && allApis.length > 0 && allRules.length > 0 && (
        <>
          <div className="d-flex justify-content-end mt-s">
            <ToggleButton data-testid="services-filter-show-more-toggle" checked={showMore} onClick={setShowMore}>
              <FormattedMessage id="admin.filter.show.more" />
            </ToggleButton>
          </div>
          {showMore && (
            <>
              <div className="row mt-m">
                <div className="col-md-6">
                  <ServiceRulesFilter
                    allOptions={allRules}
                    control={control}
                    intl={intl}
                    labelKey="admin.filter.service.rules.enabled"
                    valueKey="enabledRules"
                    disabledOptions={disabledRules}
                  />
                </div>
                <div className="col-md-6">
                  <ServiceSettingsFilter
                    control={control}
                    intl={intl}
                    labelKey="admin.filter.service.settings.enabled"
                    valueKey="enabledSettings"
                    disabledOptions={disabledSettings}
                  />
                </div>
              </div>
              <div className="row mt-m">
                <div className="col-md-6">
                  <ServiceRulesFilter
                    allOptions={allRules}
                    control={control}
                    intl={intl}
                    labelKey="admin.filter.service.rules.disabled"
                    valueKey="disabledRules"
                    disabledOptions={enabledRules}
                  />
                </div>
                <div className="col-md-6">
                  <ServiceSettingsFilter
                    control={control}
                    intl={intl}
                    labelKey="admin.filter.service.settings.disabled"
                    valueKey="disabledSettings"
                    disabledOptions={enabledSettings}
                  />
                </div>
              </div>
              <Divider className="my-m" />
              <div className="row">
                <div className="col-md-6">
                  <Controller
                    name="enabledApis"
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <MultiSelect
                        data-testid="services-filter-enabledApis"
                        className="w-100"
                        items={allApis
                          .map(id => ({ uniqueItemId: id, labelText: formatMessage({ id }) }))
                          .sort((a, b) => a.labelText.localeCompare(b.labelText, locale))}
                        selectedItems={value.map(id => ({ uniqueItemId: id, labelText: formatMessage({ id }) }))}
                        onItemSelect={uniqueItemId => {
                          if (uniqueItemId !== null) {
                            const ids = !value.includes(uniqueItemId)
                              ? value.concat(uniqueItemId)
                              : value.filter(id => id !== uniqueItemId)
                            onChange(ids)
                          } else {
                            onChange(value)
                          }
                        }}
                        onBlur={onBlur}
                        labelText={formatMessage({ id: "admin.apis" })}
                        noItemsText={formatMessage({ id: "admin.common.input.filter.no.results" })}
                        ariaSelectedAmountText=""
                        ariaOptionsAvailableText=""
                        ariaOptionChipRemovedText=""
                        chipListVisible
                      />
                    )}
                  />
                </div>
              </div>
              <div className="d-flex flex-wrap flex-md-nowrap flex-column flex-md-row mt-m">
                <Button
                  data-testid="services-filter-clear"
                  variant="secondary"
                  onClick={() => reset(initialValues)}
                  disabled={!isFiltersActive}
                >
                  <FormattedMessage id="admin.filter.clear" />
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
