import { useMutation, useQueryClient } from "@tanstack/react-query"
import { createUserAuthorizations } from "../api"

const useCreateUserAuthorizations = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createUserAuthorizations,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] })
    }
  })
}

export default useCreateUserAuthorizations
