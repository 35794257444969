import { OfficerForm } from "./OfficerForm"
import { Heading } from "suomifi-ui-components"
import { useParams } from "react-router-dom"
import useOfficers from "../hooks/useOfficers"
import { FormattedMessage } from "react-intl"

const OfficerUpdatePage = () => {
  const { uuid } = useParams()
  const officers = useOfficers()
  const officer = officers.find(o => o.uuid === uuid)
  return (
    <>
      <Heading variant="h1" className="mb-m">
        <FormattedMessage id="admin.header.update.officer" />
      </Heading>
      {officer && <OfficerForm officer={officer} />}
    </>
  )
}

export { OfficerUpdatePage }
