import { deleteOfficer, officer } from "../api"
import { ActionFunction } from "react-router-dom"

const putOfficerAction: ActionFunction = async ({ request }) => {
  try {
    return { data: await officer(await request.json()) }
  } catch (error) {
    return { error }
  }
}

const deleteOfficerAction: ActionFunction = async ({ request }) => {
  try {
    return { data: await deleteOfficer(await request.text()) }
  } catch (error) {
    return { error }
  }
}

export { putOfficerAction, deleteOfficerAction }
