import { forwardRef } from "react"
import { TextInput, TextInputProps, IconCalendar } from "suomifi-ui-components"

type Props = TextInputProps & { onChange?: (value: { target: { value: string } }) => void }

const DatePickerInput = forwardRef<HTMLInputElement, Props>(({ onChange, ...passProps }, ref) => (
  <TextInput
    {...passProps}
    icon={<IconCalendar />}
    ref={ref}
    onChange={value => {
      const target = { value: value as string, __proto__: HTMLInputElement.prototype }
      if (onChange) {
        onChange({ target })
      }
    }}
  />
))

DatePickerInput.displayName = "DatePickerInput"

export { DatePickerInput }
