import { useMutation, useQueryClient } from "@tanstack/react-query"
import { updateService } from "../api"

const useUpdateService = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateService,
    onSuccess: (data, variables) => {
      queryClient.setQueryData(["service", variables.id?.toString()], data)
    }
  })
}

export default useUpdateService
