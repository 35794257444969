import { ErrorResponse } from "../types"

export class ApiError extends Error {
  status: number
  error: ErrorResponse

  constructor(message: string, status: number, error: ErrorResponse) {
    super(message)
    this.status = status
    this.error = error
  }
}
