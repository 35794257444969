import { Heading, RadioButton, RadioButtonGroup } from "suomifi-ui-components"
import { FormattedMessage, useIntl } from "react-intl"
import { ActiveNotifications } from "./ActiveNotifications"
import { HistoryNotifications } from "./HistoryNotifications"
import { useState } from "react"
import { useNotifications } from "../hooks"
import { NavButton } from "../components/NavButton"

const NotificationsList = () => {
  const { data } = useNotifications()
  const { formatMessage } = useIntl()
  const [tab, setTab] = useState("active")

  return (
    <>
      <Heading variant="h1" className="mb-m">
        <FormattedMessage id="admin.header.hairiotiedotteet" />
      </Heading>
      <div className="d-flex justify-content-between align-items-end">
        <RadioButtonGroup
          labelText={formatMessage({ id: "admin.notifications.show" })}
          name="tab"
          value={tab}
          onChange={setTab}
        >
          <RadioButton data-testid="notifications-list-radio-active" value="active">
            <FormattedMessage id="admin.tiedote.julkaisussa" />
          </RadioButton>
          <RadioButton data-testid="notifications-list-radio-history" value="history">
            <FormattedMessage id="admin.tiedote.historia" />
          </RadioButton>
        </RadioButtonGroup>
        <NavButton data-testid="notifications-list-add-nav-button" to="new">
          <FormattedMessage id="admin.button.lisaa.tiedote" />
        </NavButton>
      </div>
      <div className="my-m">
        {data && tab === "active" && <ActiveNotifications notifications={data} />}
        {data && tab === "history" && <HistoryNotifications notifications={data} />}
      </div>
    </>
  )
}

export { NotificationsList }
