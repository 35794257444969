import { NormalizedRule, Rule, RuleConfig, RuleSet, RuleSetMap, Service } from "../types"

const reduceByKey = <T, K extends string>(group: Record<string, T[]>, value: T, key: K) => {
  if (group[key]) {
    group[key].push(value)
  } else {
    group[key] = [value]
  }
  return group
}

const reduceRulesByType = (
  rulesByType: Record<string, NormalizedRule[]>,
  rule: NormalizedRule,
  ruleSetType: string
) => {
  return reduceByKey(rulesByType, rule, getGroupKey(ruleSetType, rule.ruleType))
}

const reduceRulesByGroup = (rulesByGroup: Record<string, NormalizedRule[]>, rule: NormalizedRule) => {
  return reduceByKey(rulesByGroup, rule, rule.group)
}

const getRuleSetGroupingKey = (ruleSet: RuleSet) => {
  switch (ruleSet.type) {
    case "AUTHORIZATION_DELEGATE":
    case "AUTHORIZATION_DELEGATE_UTU":
    case "AUTHORIZATION_MANDATE_VTJ":
    case "AUTHORIZATION_MANDATE_UTU":
    case "AUTHORIZATION_HUKO":
      return "AUTHORIZATION"
    case "DELEGATE_UTU":
      return "DELEGATE"
    default:
      return ruleSet.type
  }
}

const ruleSetOrders: { [type: string]: number | undefined } = {
  DELEGATE: 1,
  DELEGATE_UTU: 2,
  AUTHORIZATION_DELEGATE: 3,
  AUTHORIZATION_DELEGATE_UTU: 4,
  AUTHORIZATION: 5,
  AUTHORIZATION_HUKO: 6,
  AUTHORIZATION_MANDATE_VTJ: 7,
  AUTHORIZATION_MANDATE_UTU: 8,
  ORG_ROLE: 9,
  ORG_PERSON_MANDATES: 10,
  ORG_MANDATES: 11
}

const typeOrders: { [key: string]: number | undefined } = {
  VTJ: 1,
  HUKO: 2,
  UTU: 3,
  VIRRE: 4,
  VARE: 5
}

const groupOrders: { [key: string]: number | undefined } = {
  "yleiset.saannot": 1,
  "huoltajuus.saannot": 2,
  "edunvalvonta.saannot.uudet": 3
}

const getGroupKey = (ruleSetType: string, ruleType: string) => {
  if (ruleType == "VTJ") {
    switch (ruleSetType) {
      case "AUTHORIZATION":
      case "AUTHORIZATION_HUKO":
        return ruleType + ".paamiestarkastus"
      case "AUTHORIZATION_DELEGATE":
        return ruleType + ".asiamiestarkastus"
      case "AUTHORIZATION_MANDATE_VTJ":
      case "ORG_PERSON_MANDATES":
        return ruleType + ".paamiesmandate"
    }
  } else if (ruleType == "UTU") {
    switch (ruleSetType) {
      case "AUTHORIZATION_DELEGATE_UTU":
      case "DELEGATE_UTU":
        return ruleType + ".asiamiestarkastus"
      case "AUTHORIZATION_MANDATE_UTU":
      case "ORG_PERSON_MANDATES":
        return ruleType + ".paamiesmandate"
    }
  } else if (ruleType == "VARE" && ruleSetType == "AUTHORIZATION") {
    return "VTJ_VARE"
  }
  return ruleType
}

const getRuleSetOrder = (ruleSet: RuleSet) => ruleSetOrders[ruleSet.type] || 12
const getRuleTypeOrder = (rule: Rule) => typeOrders[rule.ruleType] || 7
const getRuleGroupOrder = (rule: Rule) => groupOrders[rule.group] || 4

const compareRuleSets = (a: RuleSet, b: RuleSet) => getRuleSetOrder(a) - getRuleSetOrder(b)
const compareRuleTypes = (a: Rule, b: Rule) => getRuleTypeOrder(a) - getRuleTypeOrder(b)
const compareRuleGroups = (a: Rule, b: Rule) => getRuleGroupOrder(a) - getRuleGroupOrder(b)
const compareRuleIds = (a: Rule, b: Rule) => a.ruleId.localeCompare(b.ruleId)

const normalizeRule = (rule: Rule, type: string, ruleConfig: { [key: string]: RuleConfig }): NormalizedRule => {
  const { ruleId } = rule
  return { ...rule, type, ruleConfig: ruleConfig[ruleId] }
}

const groupRules = (ruleSetMap: RuleSetMap, service: Service, selected = true) => {
  return Object.values(ruleSetMap)
    .sort(compareRuleSets)
    .filter(ruleSet => service.serviceConfig.enabledApis.includes(getRuleSetGroupingKey(ruleSet)))
    .reduce<Record<string, Record<string, Record<string, NormalizedRule[]>>>>((groupedRules, ruleSet) => {
      const rulesByType = Object.entries(
        Object.values(ruleSet.rules)
          .filter(rule => !selected || rule.selected)
          .sort(compareRuleIds)
          .sort(compareRuleTypes)
          .map(rule => {
            return normalizeRule(rule, ruleSet.type, ruleSet.ruleConfig)
          })
          .reduce<Record<string, NormalizedRule[]>>((acc, rule) => reduceRulesByType(acc, rule, ruleSet.type), {})
      ).reduce<Record<string, Record<string, NormalizedRule[]>>>((acc, [type, rules]) => {
        acc[type] = rules.sort(compareRuleGroups).reduce<Record<string, NormalizedRule[]>>(reduceRulesByGroup, {})
        return acc
      }, {})

      const key = getRuleSetGroupingKey(ruleSet)
      if (groupedRules[key]) {
        groupedRules[key] = { ...groupedRules[key], ...rulesByType }
      } else {
        groupedRules[key] = rulesByType
      }
      return groupedRules
    }, {})
}

export { groupRules }
