import { useMutation, useQueryClient } from "@tanstack/react-query"
import { updateRuleSets } from "../api"

const useUpdateRuleSets = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateRuleSets,
    onSuccess: (data, variables) => {
      queryClient.setQueryData(["ruleSet", variables.uuid?.toString()], data)
    }
  })
}

export default useUpdateRuleSets
