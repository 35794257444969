import { useBeforeUnload, useBlocker } from "react-router-dom"
import { Button, Modal, ModalContent, ModalFooter, ModalTitle } from "suomifi-ui-components"
import { FormattedMessage } from "react-intl"

type Props = {
  shouldBlock?: boolean
}

const NavigationBlocker = ({ shouldBlock = false }: Props) => {
  const { state, proceed, reset } = useBlocker(shouldBlock)
  useBeforeUnload(event => {
    if (shouldBlock) {
      event.preventDefault()
      event.returnValue = true
    }
  })

  return (
    <Modal appElementId="root" visible={state === "blocked"}>
      <ModalContent>
        <ModalTitle>
          <FormattedMessage id="admin.warn.navigation.header" />
        </ModalTitle>
        <FormattedMessage id="admin.warn.navigation.message" />
      </ModalContent>
      <ModalFooter>
        <Button data-testid="confirm-navigation-yes-button" onClick={proceed}>
          <FormattedMessage id="admin.button.kylla" />
        </Button>
        <Button data-testid="confirm-navigation-no-button" onClick={reset} variant="secondary">
          <FormattedMessage id="admin.button.ei" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { NavigationBlocker }
