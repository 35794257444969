import { ExternalLink } from "suomifi-ui-components"
import { FormattedMessage, useIntl } from "react-intl"
import styles from "./Footer.module.scss"
import { AdminBrand } from "../components/AdminBrand"

const Footer = () => {
  const { formatMessage } = useIntl()

  return (
    <footer className={styles.footer}>
      <div className="container py-m">
        <div className={styles.content}>
          <div>
            <AdminBrand />
            <FormattedMessage id="admin.serviceProviderText" />
          </div>
          <ExternalLink
            labelNewWindow={formatMessage({ id: "admin.footer.privacyLinkLabel" })}
            href={formatMessage({ id: "admin.footer.privacyLinkHref" })}
          >
            <FormattedMessage id="admin.footer.privacyLinkText" />
          </ExternalLink>
        </div>
      </div>
    </footer>
  )
}

export { Footer }
