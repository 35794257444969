import { Checkbox } from "suomifi-ui-components"
import { IntlShape, useIntl } from "react-intl"
import { NormalizedRule } from "../../types"
import { Issue } from "../../types"

export const ISSUES_SETTING_KEY = "allowedissues"

type Props = {
  rule: NormalizedRule
  issues: Issue[]
  onClick: (rule: NormalizedRule, key: string, value: string) => void
}

const createNewValue = (selectedValues: string[], currentValue: string, select: boolean) => {
  if (!selectedValues.includes(currentValue)) {
    selectedValues.push(currentValue)
  }
  return selectedValues.filter(value => value !== currentValue || select).join(",")
}

const formatHukoRoles = (issue: Issue) => {
  return issue.hukoRoles && issue.hukoRoles.length > 0 ? " (" + issue.hukoRoles.join(", ") + ")" : ""
}

const createIssueSelection = (
  rule: NormalizedRule,
  issue: Issue,
  selectedIssues: string[],
  onClick: (rule: NormalizedRule, key: string, value: string) => void,
  intl: IntlShape
) => {
  const checked = selectedIssues.includes(issue.uri)
  return (
    <Checkbox
      key={issue.uri}
      data-testid={`issues-rule-config-${rule.type}-${issue.uri}`}
      checked={checked}
      onClick={() => onClick(rule, ISSUES_SETTING_KEY, createNewValue(selectedIssues, issue.uri, !checked))}
      hintText={issue.uri}
    >
      <div>
        {issue.labels[intl.locale]} {issue.active ? "" : " ***"}
      </div>
      {issue.hukoRoles && <div>{formatHukoRoles(issue)}</div>}
    </Checkbox>
  )
}

const IssuesRuleConfig = ({ rule, issues, onClick }: Props) => {
  const intl = useIntl()
  const { ruleConfig } = rule
  const { [ISSUES_SETTING_KEY]: allowedIssues } = ruleConfig
  const selectedIssues = allowedIssues ? allowedIssues.split(",") : []
  return (
    <>
      {issues
        .sort((a, b) => a.uri.localeCompare(b.uri))
        .map(issue => createIssueSelection(rule, issue, selectedIssues, onClick, intl))}
    </>
  )
}

export default IssuesRuleConfig
