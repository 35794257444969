import { Organization } from "../types"
import { Button, IconEdit } from "suomifi-ui-components"
import OrganizationEditModal from "./OrganizationEditModal"
import { useState } from "react"
import { TimedToast } from "../components/TimedToast"
import { FormattedMessage, useIntl } from "react-intl"

type Props = {
  organization: Organization
}

const OrganizationEditButton = ({ organization }: Props) => {
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState("")

  const intl = useIntl()

  const handleClick = () => {
    setStatus("")
    setVisible(true)
  }

  const handleSuccess = () => {
    setStatus("success")
    setVisible(false)
  }

  return (
    <>
      <TimedToast status={status}>
        <FormattedMessage id="admin.info.tallennettu" />
      </TimedToast>
      <Button
        aria-label={intl.formatMessage({ id: "admin.button.muokkaa" })}
        icon={<IconEdit />}
        variant="secondaryNoBorder"
        onClick={handleClick}
        data-testid="org-edit-button"
      />
      <OrganizationEditModal
        visible={visible}
        organization={organization}
        onSuccess={handleSuccess}
        onClose={() => setVisible(false)}
      />
    </>
  )
}

export default OrganizationEditButton
