import { Modal } from "suomifi-ui-components"
import { Organization } from "../types"
import OrganizationAddModalForm from "./OrganizationAddModalForm"

type Props = {
  visible: boolean
  onSuccess: (organization: Organization) => void
  onClose: () => void
}

const OrganizationAddModal = ({ visible, onSuccess, onClose }: Props) => {
  return (
    <Modal visible={visible} appElementId="root">
      <OrganizationAddModalForm onSuccess={onSuccess} onClose={onClose} />
    </Modal>
  )
}

export default OrganizationAddModal
