const personIdDatePartRegex = /^\d+$/
const companyIdRegex = /^[0-9]{7}-[0-9]/
const checkMarks = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "H",
  "J",
  "K",
  "L",
  "M",
  "N",
  "P",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y"
]

const isValidFinnishSocialSecurityNumber = (id: string): boolean => {
  if (!id || typeof id === "undefined" || id.length !== 11) {
    return false
  }
  if (personIdDatePartRegex.test(id.substring(0, 6))) {
    if (isValidDate(id.substring(0, 7))) {
      if (personIdDatePartRegex.test(id.substring(7, 10))) {
        return isMatchControl(id)
      }
    }
  }
  return false
}

const isValidFinnishCompanyId = (id: string): boolean => {
  if (!id || typeof id === "undefined" || id.length !== 9) {
    return false
  }
  if (companyIdRegex.test(id)) {
    let calculated =
      parseInt(id.charAt(0), 10) * 7 +
      parseInt(id.charAt(1), 10) * 9 +
      parseInt(id.charAt(2), 10) * 10 +
      parseInt(id.charAt(3), 10) * 5 +
      parseInt(id.charAt(4), 10) * 8 +
      parseInt(id.charAt(5), 10) * 4 +
      parseInt(id.charAt(6), 10) * 2
    calculated = calculated % 11
    if (calculated !== 1) {
      if (calculated !== 0) {
        calculated = 11 - calculated
      }
      return calculated === parseInt(id.charAt(8), 10)
    }
  }
  return false
}

const isValidDate = (date: string): boolean => {
  const d: number = parseInt(date.substring(0, 2), 10)
  const m: number = parseInt(date.substring(2, 4), 10) - 1
  let y: number = parseInt(date.substring(4, 6), 10)
  const e: string = date.substring(6)
  if (["-", "Y", "X", "W", "V", "U"].includes(e)) {
    y += 1900
  } else if (["A", "B", "C", "D", "E", "F"].includes(e)) {
    y += 2000
  } else if (e === "+") {
    y += 1800
  } else {
    return false
  }
  const D = new Date(y, m, d)
  return D.getFullYear() === y && D.getMonth() === m && D.getDate() === d
}

const isMatchControl = (id: string) => {
  const numberPart = parseInt(id.substring(0, 6) + id.substring(7, 10), 10)
  const checkMark = id.charAt(10)
  return checkMark === checkMarks[numberPart % 31]
}

export { isValidFinnishSocialSecurityNumber, isValidFinnishCompanyId }
