import { ReactNode, useState } from "react"
import { Notification } from "suomifi-ui-components"

import toastStyles from "./TimedToast.module.scss"

type Props = {
  id?: string
  children: ReactNode
  onClose?: () => void
}

const ErrorNotification = ({ id, children, onClose }: Props) => {
  const [visible, setVisible] = useState(true)

  if (!visible) {
    return null
  }

  const onCloseButtonClick = () => {
    setVisible(false)
    onClose?.()
  }

  return (
    <Notification
      id={id}
      className={toastStyles.timedToast}
      status="error"
      closeText=""
      onCloseButtonClick={onCloseButtonClick}
    >
      {children}
    </Notification>
  )
}

export { ErrorNotification }
