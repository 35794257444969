import { useEffect, useState } from "react"
import { StatusText, StatusTextProps } from "suomifi-ui-components"

export const TimedStatusText = ({
  show,
  onHide,
  ...passProps
}: { show: boolean; onHide: () => void } & StatusTextProps) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (show) {
      setVisible(true)
      setTimeout(() => {
        setVisible(false)
        onHide()
      }, 3000)
    }
  }, [show, onHide])

  return <div role="status">{visible && <StatusText {...passProps}></StatusText>}</div>
}
