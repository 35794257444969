import { useOutletContext } from "react-router-dom"
import type { Language } from "../utils/languages"

export type RootContext = {
  locale: Language
}

const useRootContext = () => {
  return useOutletContext<RootContext>()
}

export default useRootContext
