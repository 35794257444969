type Language = "fi" | "sv"

const languages: Language[] = ["fi", "sv"]

const languageNames: Record<Language, string> = {
  fi: "Suomeksi (FI)",
  sv: "På svenska (SV)"
}

export { languages, languageNames }
export type { Language }
