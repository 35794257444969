import { useMutation, useQueryClient } from "@tanstack/react-query"
import { createUserIdentifier } from "../api"

const useCreateUserIdentifier = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createUserIdentifier,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] })
    }
  })
}

export default useCreateUserIdentifier
