import { Checkbox } from "suomifi-ui-components"
import { NormalizedRule } from "../../types"
import { useMemo } from "react"
import { FormattedMessage } from "react-intl"

export const ROLES_SETTING_KEY = "roles"
export const LLCROLES_SETTING_KEY = "llcroles"
export const ASSOCIATION_ROLES_SETTING_KEY = "association_roles"

type Props = {
  rule: NormalizedRule
  configKey: string
  onClick: (rule: NormalizedRule, key: string, value: string) => void
  componentConfig: string
}

type RoleGroups = {
  [group: string]: string[]
}

const groupRoles = (config: string) => {
  return config.split(";").reduce<RoleGroups>((groups, groupConfig) => {
    if (groupConfig) {
      const [group, roles] = groupConfig.split("=")
      groups[group] = roles.split(",")
    }
    return groups
  }, {})
}

const serializeRoles = (groups: RoleGroups) => {
  return Object.entries(groups)
    .map(([group, roles]) => group + "=" + roles.join(","))
    .join(";")
}

const RoleRuleConfig = ({ rule, configKey, onClick, componentConfig }: Props) => {
  const availableRoles = useMemo(() => groupRoles(componentConfig), [componentConfig])
  const selectedRoles = useMemo(() => groupRoles(rule.ruleConfig[configKey]), [rule, configKey])

  const removeGroup = (group: string) => {
    const value = { ...selectedRoles }
    delete value[group]
    return value
  }

  const handleGroupChange = (group: string, checked: boolean) => {
    return checked ? { ...selectedRoles, [group]: availableRoles[group] } : removeGroup(group)
  }

  const handleRoleChange = (group: string, role: string, checked: boolean) => {
    if (checked) {
      return { ...selectedRoles, [group]: selectedRoles[group].concat(role) }
    }
    const roles = selectedRoles[group].filter(value => value !== role)
    if (roles.length) {
      return { ...selectedRoles, [group]: roles }
    }
    return removeGroup(group)
  }

  const handleGroupClick = (group: string, checked: boolean) => {
    onClick(rule, configKey, serializeRoles(handleGroupChange(group, checked)))
  }

  const handleRoleClick = (group: string, role: string, checked: boolean) => {
    onClick(rule, configKey, serializeRoles(handleRoleChange(group, role, checked)))
  }

  return (
    <>
      {Object.entries(availableRoles).map(([group, roles]) => (
        <div key={group}>
          <Checkbox
            data-testid={`role-rule-config-available-${group}-${rule.ruleId}`}
            checked={selectedRoles[group] !== undefined}
            onClick={({ checkboxState }) => handleGroupClick(group, checkboxState)}
          >
            {group}
          </Checkbox>
          {selectedRoles[group] && (
            <div className="ms-l">
              {roles.map(role => (
                <Checkbox
                  key={role}
                  data-testid={`status-rule-config-selected-${rule.ruleId}-${group}-${role}`}
                  checked={selectedRoles[group].includes(role)}
                  onClick={({ checkboxState }) => handleRoleClick(group, role, checkboxState)}
                >
                  <FormattedMessage id={`role.${role}`} />
                </Checkbox>
              ))}
            </div>
          )}
        </div>
      ))}
    </>
  )
}

export default RoleRuleConfig
