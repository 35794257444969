import { Button, IconLogout } from "suomifi-ui-components"
import { ReactNode } from "react"
import { getToken } from "../utils/csrf"

const LogoutButton = ({ children }: { children: ReactNode }) => {
  return (
    <form action="/saml/logout" method="post">
      <input type="hidden" name="_csrf" value={getToken()} />
      <Button data-testid="logout-button" type="submit" icon={<IconLogout />} variant="secondaryNoBorder">
        {children}
      </Button>
    </form>
  )
}

export { LogoutButton }
