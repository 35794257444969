import { useEffect, useState } from "react"

const useConfirmSave = <T>(isSubmitSuccess: boolean, onConfirm: (t: T) => void) => {
  const [valuesToSubmit, setValuesToSubmit] = useState<T>()
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const openModal = (values: T) => {
    setValuesToSubmit(values)
    setIsConfirmOpen(true)
  }

  const closeModal = () => setIsConfirmOpen(false)

  useEffect(() => {
    if (isSubmitSuccess) {
      closeModal()
    }
  }, [isSubmitSuccess])

  return {
    openModal,
    confirm: () => onConfirm(valuesToSubmit as T),
    closeModal,
    isConfirmOpen
  }
}

export default useConfirmSave
