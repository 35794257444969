import { Control, Controller } from "react-hook-form"
import { IntlShape } from "react-intl"
import { MultiSelect } from "suomifi-ui-components"
import { ServiceRulesFilterValue, ServicesFilterValue } from "./servicesFilter.types"

export const ServiceRulesFilter = ({
  control,
  intl: { formatMessage, locale },
  labelKey,
  valueKey,
  allOptions,
  disabledOptions
}: {
  control: Control<ServicesFilterValue>
  intl: IntlShape
  labelKey: string
  valueKey: "enabledRules" | "disabledRules"
  allOptions: ServiceRulesFilterValue[]
  disabledOptions: ServiceRulesFilterValue[]
}) => {
  const getRuleLabel = ({ ruleId, valueLabelKey }: { ruleId: string; valueLabelKey?: string }, short = false) => {
    const ruleLabel = `${formatMessage({ id: ruleId })}`
    const valueLabel = valueLabelKey ? `${formatMessage({ id: valueLabelKey })}` : ""

    if (short && valueLabelKey) {
      return `${ruleId} ${valueLabel}`
    } else if (short || !valueLabelKey) {
      return `${ruleId} ${ruleLabel}`
    } else {
      return `${ruleId} ${ruleLabel} - ${valueLabel}`
    }
  }

  return (
    <Controller
      name={valueKey}
      control={control}
      render={({ field: { value, onChange, onBlur } }) => (
        <MultiSelect
          data-testid={`services-filter-${valueKey}`}
          className="w-100"
          items={allOptions
            .map(({ id, ...rest }) => ({
              uniqueItemId: id,
              labelText: getRuleLabel(rest),
              chipText: getRuleLabel(rest, true),
              disabled: disabledOptions.some(rule => rule.id === id)
            }))
            .sort((a, b) => a.labelText.localeCompare(b.labelText, locale))}
          selectedItems={value.map(({ id, ...rest }) => ({
            uniqueItemId: id,
            labelText: getRuleLabel(rest),
            chipText: getRuleLabel(rest, true)
          }))}
          onItemSelect={uniqueItemId => {
            const newValue = !value.some(({ id }) => id === uniqueItemId)
              ? value.concat(allOptions.find(({ id }) => id === uniqueItemId) as ServiceRulesFilterValue)
              : value.filter(({ id }) => id !== uniqueItemId)
            onChange(newValue)
          }}
          onBlur={onBlur}
          labelText={formatMessage({ id: labelKey })}
          noItemsText={formatMessage({ id: "admin.common.input.filter.no.results" })}
          ariaSelectedAmountText=""
          ariaOptionsAvailableText=""
          ariaOptionChipRemovedText=""
          chipListVisible
        />
      )}
    />
  )
}
