import { useState, useMemo, useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  Heading,
  TextInput,
  Button,
  IconEdit,
  IconRemove,
  IconSearch,
  Modal,
  ModalFooter,
  ModalTitle,
  ModalContent
} from "suomifi-ui-components"
import { useNavigate } from "react-router-dom"
import { useDeleteUser, useUsers } from "../hooks"
import { filterByTerm, flattenUserForDeepSearch } from "../utils/filtering"
import { User } from "../types"

const UsersList = () => {
  const { data: users } = useUsers()
  const [userFilter, setUserFilter] = useState<string>()
  const { isSuccess: isUserDeleted, isPending: isDeletingUser, mutate: deleteUser } = useDeleteUser()
  const [userToDelete, setUserToDelete] = useState<User>()

  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  useEffect(() => {
    if (isUserDeleted) {
      setUserToDelete(undefined)
    }
  }, [isUserDeleted])

  const orderedUsers = useMemo(() => {
    if (users) {
      return users.sort((a, b) => a.name.localeCompare(b.name))
    }
    return []
  }, [users])

  const renderHeading = () => {
    return (
      <div className="row mb-s">
        <div className="col-12 col-md">
          <TextInput
            data-testid="user-filter-input"
            labelText={formatMessage({ id: "admin.filter.users" })}
            visualPlaceholder={formatMessage({ id: "admin.info.kirjoita" })}
            icon={<IconSearch />}
            onChange={value => setUserFilter(value ? (value as string) : undefined)}
            fullWidth
          />
        </div>
        <div className="col mt-s d-flex align-items-end justify-content-end">
          <Button
            data-testid="users-list-new"
            onClick={() => {
              navigate("/users/new")
            }}
          >
            {formatMessage({ id: "admin.button.lisaa.kayttaja" })}
          </Button>
        </div>
      </div>
    )
  }

  const renderTable = () => {
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="admin.field.kayttaja" />
            </th>
            <th>
              <FormattedMessage id="admin.button.muokkaa" />
            </th>
            <th>
              <FormattedMessage id="admin.button.poista" />
            </th>
          </tr>
        </thead>
        <tbody>
          {orderedUsers &&
            orderedUsers
              .filter(user => filterByTerm(userFilter, flattenUserForDeepSearch(user)))
              .map(user => (
                <tr key={user.uuid}>
                  <td className="w-100 text-break">{user.name}</td>
                  <td>
                    <Button
                      data-testid={`users-list-edit-${user.uuid}`}
                      variant="secondaryNoBorder"
                      onClick={() => navigate(user.uuid)}
                    >
                      <IconEdit />
                    </Button>
                  </td>
                  <td>
                    <Button
                      data-testid={`users-list-remove-${user.uuid}`}
                      variant="secondaryNoBorder"
                      onClick={() => setUserToDelete(user)}
                    >
                      <IconRemove />
                    </Button>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    )
  }

  return (
    <>
      <Heading variant="h1" className="mb-m">
        <FormattedMessage id="admin.header.users" />
      </Heading>
      <Modal appElementId="root" visible={userToDelete !== undefined}>
        {userToDelete && (
          <>
            <ModalContent>
              <ModalTitle>
                <FormattedMessage id="admin.modal.poistakayttaja.header" />
              </ModalTitle>
              <FormattedMessage id="admin.modal.poistakayttaja.otsikko" /> {userToDelete.name}?
            </ModalContent>
            <ModalFooter>
              <Button
                data-testid="users-list-delete-user-yes"
                disabled={isDeletingUser}
                onClick={() => deleteUser(userToDelete.uuid)}
              >
                <FormattedMessage id="admin.button.kylla" />
              </Button>
              <Button
                data-testid="users-list-delete-user-no"
                disabled={isDeletingUser}
                onClick={() => setUserToDelete(undefined)}
                variant="secondary"
              >
                <FormattedMessage id="admin.button.ei" />
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
      {renderHeading()}
      {renderTable()}
    </>
  )
}

export { UsersList }
