import { Fragment, useState } from "react"
import { useIntl } from "react-intl"
import { Alert } from "suomifi-ui-components"
import { useAdminNotifications, useIsMobile } from "../hooks"

const AdminUiNotifications = () => {
  const { data: notifications } = useAdminNotifications()
  const { formatMessage } = useIntl()
  const isMobile = useIsMobile()
  const [closed, setClosed] = useState<number[]>([])

  const onClose = (id: number) => setClosed(prevClosed => [...prevClosed, id])

  return (
    <>
      {notifications &&
        notifications
          .filter(({ id }) => !closed.includes(id))
          .map(({ id, text }, index) => (
            <Alert
              key={index}
              closeText={formatMessage({ id: "common.close" })}
              onCloseButtonClick={() => onClose(id)}
              smallScreen={isMobile}
              status="error"
              closeButtonProps={
                {
                  "data-testid": `close-alert-${index}`
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } as any
              }
            >
              {text.split("\n").map((line, index) => (
                <Fragment key={index}>
                  {index > 0 && <br />}
                  {line}
                </Fragment>
              ))}
            </Alert>
          ))}
    </>
  )
}

export { AdminUiNotifications }
