import { useMutation, useQueryClient } from "@tanstack/react-query"
import { updateOrganization } from "../api"
import { Organization } from "../types"
import { compareOrganizations } from "../utils/organizations"

const useUpdateOrganization = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateOrganization,
    onSuccess: data => {
      queryClient.setQueryData<Organization[]>(["organizations"], old =>
        old ? old.map(o => (o.id === data.id ? data : o)).sort(compareOrganizations) : undefined
      )
    }
  })
}

export default useUpdateOrganization
