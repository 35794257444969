import { Notification } from "../types"
import { useMemo } from "react"
import { NotificationTable } from "./NotificationTable"

const ActiveNotifications = ({ notifications }: { notifications: Notification[] }) => {
  const activeNotifications = useMemo(() => {
    const now = new Date().getTime()
    return notifications.filter(notification => now < notification.endDate).sort((a, b) => a.startDate - b.startDate)
  }, [notifications])

  return <NotificationTable notifications={activeNotifications} />
}

export { ActiveNotifications }
