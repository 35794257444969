import { useIntl } from "react-intl"
import useCreateOrganisation from "../hooks/useCreateOrganisation"
import OrganizationModalForm from "./OrganizationModalForm"
import { Organization } from "../types"
import { useEffect } from "react"

type Props = {
  onSuccess: (organization: Organization) => void
  onClose: () => void
}

const OrganizationAddModalForm = ({ onSuccess, onClose }: Props) => {
  const { formatMessage } = useIntl()
  const { mutate, isSuccess, data, error } = useCreateOrganisation()

  useEffect(() => {
    if (isSuccess) {
      onSuccess(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  return (
    <OrganizationModalForm
      title={formatMessage({ id: "admin.organizations.modal.add.title" })}
      onSave={mutate}
      error={error}
      onClose={onClose}
    />
  )
}

export default OrganizationAddModalForm
