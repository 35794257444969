import styles from "./Header.module.scss"
import { useLocation } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { LanguageMenu, LanguageMenuItem } from "suomifi-ui-components"
import { LogoutButton } from "../components/LogoutButton"
import { Language, languageNames, languages } from "../utils/languages"
import { LoginButton } from "../components/LoginButton"
import { landing, unauthorized } from "../utils/routerPaths"
import { useDisplayName } from "../hooks"
import { AdminBrand } from "../components/AdminBrand"

type Props = {
  lang: Language
  onLangChange: (lang: Language) => void
  displayName?: string
}

const Header = ({ lang, onLangChange }: Props) => {
  const { pathname } = useLocation()
  const showDisplayName = ![landing, unauthorized].includes(pathname)
  const { data: displayName } = useDisplayName({ enabled: showDisplayName })

  return (
    <header className={styles.header}>
      <div className="container py-xs">
        <div className="row">
          <div className="col-lg">
            <AdminBrand />
          </div>
          <div className="col-lg d-flex flex-column flex-md-row justify-content-between mt-xs mt-lg-0">
            <LanguageMenu buttonText={languageNames[lang]} aria-label={languageNames[lang]}>
              {languages.map(language => (
                <LanguageMenuItem
                  lang={language}
                  key={language}
                  selected={language === lang}
                  onSelect={() => onLangChange(language)}
                >
                  <span lang={language}>{languageNames[language]}</span>
                </LanguageMenuItem>
              ))}
            </LanguageMenu>
            {pathname === landing ? (
              <LoginButton lang={lang} />
            ) : (
              <div className="d-flex align-items-center gap-xs ms-xs">
                <span>{displayName}</span>
                <LogoutButton>
                  <FormattedMessage id="admin.logout" />
                </LogoutButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

export { Header }
