import { useQuery } from "@tanstack/react-query"
import { getRuleSetAllIssues } from "../api"

const useRuleSetAllIssues = (ruleSetType: string, uuid: unknown) => {
  return useQuery({
    queryKey: ["rulesetallissues", ruleSetType + uuid],
    queryFn: () => getRuleSetAllIssues(ruleSetType, uuid)
  })
}

export default useRuleSetAllIssues
